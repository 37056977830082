import JwtServices from "../../services/jwt.services";
import moment from "moment";
moment.locale("id");
// action type
export const GET_TOWER_RADIO_BROADCASTING = "getTowerRadioBroadcasting";
export const GET_ORGANIZERS_RADIO_BROADCASTING =
  "getOrganizersRadioBroadcasting";
export const GET_RADIO_TRANSMITTER_COVERAGE = "getRadioTransmitterCoverage";
export const GET_RADIO_BROADCASTING_SERVICE_COVERAGE =
  "getRadioBroadcastingServiceCoverage";
export const GET_TOWER_RADIO_SUB_SERVICE = "getTowerRadioSubService"
export const GET_COVERAGE_RADIO_RADIUS = "getCoverageRadioRadius"

// mutations type
export const SET_TOWER_RADIO_BROADCASTING = "setTowerRadioBroadcasting";
export const SET_ORGANIZERS_RADIO_BROADCASTING =
  "setOrganizersRadioBroadcasting";
export const SET_RADIO_TRANSMITTER_COVERAGE = "setRadioTransmitterCoverage";
export const SET_RADIO_BROADCASTING_SERVICE_COVERAGE =
  "setRadioBroadcastingServiceCoverage";
export const SET_TOWER_RADIO_SUB_SERVICE = "setTowerRadioSubService"
export const SET_COVERAGE_RADIO_RADIUS = "SetCoverageRadioRadius"


const state = {
  towerRadioBroadcasting: null,
  organizerRadioBroadcasting: null,
  towerRadioSubService: [],

  // coverage
  radioTransmitterCoverage: null,
  radioBroadcastingServiceCoverage: null,
  coverageRadioRadius:[],
  error: null,
};

const getters = {
  towerRadioBroadcasting: (state) =>
    state.towerRadioBroadcasting && state.towerRadioBroadcasting.towers
      ? state.towerRadioBroadcasting.towers
      : [],
  paginationTowerRadioBroadcasting: (state) =>
    state.towerRadioBroadcasting ? state.towerRadioBroadcasting.pagination : {},
  dateUpdateRadioBroadcasting: (state) =>
    state.towerRadioBroadcasting
      ? moment(state.towerRadioBroadcasting.date_updated).format("L")
      : "-",
  organizerRadioBroadcasting: (state) => {
    if (state.organizerRadioBroadcasting) {
      const { sub_services } = state.organizerRadioBroadcasting;

      let categories = [];
      let series = [];
      for (let key in sub_services) {
        
        // if(key == 0){
          for (let keyRegions in sub_services[key].regions) {
            if (key == 0) {
              categories[keyRegions] = sub_services[key].regions[keyRegions].region;
              series[key] = {
                name: sub_services[key].name,
                data: [ ...(series[key] ? series[key].data : []),  sub_services[key].regions[keyRegions].total],
              };
            } 
            else {
             
              if(categories.find((item)=>{
                return item == sub_services[key].regions[keyRegions].region
              })){
                const index = categories.findIndex(
                  (item) => item == sub_services[key].regions[keyRegions].region
                );
                if(keyRegions == 0){
                  series[key] = {
                    name: sub_services[key].name,
                    data:[]
                  }
                  // series[key].data[0] = 1
                }
                series[key].data[index] = sub_services[key].regions[keyRegions].total
              } else {
                categories= [...categories, sub_services[key].regions[keyRegions].region]
                // series[key] = {}
                if(!series[key]){
                  series[key]={
                    data: []
                  }
                }
                series[key].data[categories.length - 1] = sub_services[key].regions[keyRegions].total
              }
              
            }
          }
        // }
     
      }
      return {
        categories,
        series,
      };
    } else {
      return {
        categories: [],
        series: [],
      };
    }
  },
  //   coverage
  radioTransmitterCoverage: (state) =>
    state.radioTransmitterCoverage && state.radioTransmitterCoverage.coverage
      ? state.radioTransmitterCoverage.coverage
      : [],
  paginationRadioTransmitterCoverage: (state) =>
    state.radioTransmitterCoverage
      ? state.radioTransmitterCoverage.pagination
      : {},
  dateUpdateRadioBroadcastingServiceCoverage: (state) =>
    state.radioBroadcastingServiceCoverage
      ? moment(state.radioBroadcastingServiceCoverage.date_updated).format("L")
      : "-",
  radioBroadcastingServiceCoverage: (state) => {
    if (state.radioBroadcastingServiceCoverage) {
      const { regions } = state.radioBroadcastingServiceCoverage;
      let categories = [];
      let dataSeries = []
      for (let key in regions) {
        categories[key] = regions[key].region;
        dataSeries[key] =  regions[key].total
      }
      return {
        categories,
        series:[
          {
            data: dataSeries
          }
        ],
      };
    } else {
      return {
        categories: [],
        series: [],
      };
    }
  },
  towerRadioSubService:(state)=>{
    return state.towerRadioSubService
  },
  coverageRadioRadius:(state)=>{
    const radius = state.coverageRadioRadius.map((item)=>{
      return {
        name: item.replace('Coverage Radio ',''),
        value: item
      }
    })
    return radius.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  }
};

const actions = {
  async [GET_TOWER_RADIO_BROADCASTING](context, payload) {
    try {
      const url = new URL(
        process.env.VUE_APP_API_URL + `api/v1/id/broadcast/radio/towers`
      );
      for (let key in payload) {
        url.searchParams.append(key, payload[key]);
      }
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JwtServices.getToken()}`,
        },
      });
      const result = await response.json();
      if (result.error) throw result.message;
      context.commit(SET_TOWER_RADIO_BROADCASTING, result.data);
      return response;
    } catch (error) {
      context.commit(SET_TOWER_RADIO_BROADCASTING, null);
      console.log(error);
    }
  },
  async [GET_ORGANIZERS_RADIO_BROADCASTING](context, payload) {
    try {
      const url = new URL(
        process.env.VUE_APP_API_URL +
          `api/v1/id/broadcast/radio/towers/total`
      );
      for (let key in payload) {
        url.searchParams.append(key, payload[key]);
      }
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JwtServices.getToken()}`,
        },
      });
      const result = await response.json();
      if (result.error) throw result.message;
      context.commit(SET_ORGANIZERS_RADIO_BROADCASTING, result.data);
      return result;
    } catch (error) {
      context.commit(SET_ORGANIZERS_RADIO_BROADCASTING, null);
      console.log(error);
    }
  },
  async [GET_RADIO_TRANSMITTER_COVERAGE](context, payload) {
    try {
      const url = new URL(
        process.env.VUE_APP_API_URL + `api/v1/id/broadcast/radio/coverage/`
      );
      for (let key in payload) {
        url.searchParams.append(key, payload[key]);
      }
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JwtServices.getToken()}`,
        },
      });
      const result = await response.json();
      if (result.error) throw result;
      context.commit(SET_RADIO_TRANSMITTER_COVERAGE, result.data);
      return result;
    } catch (error) {
      context.commit(SET_RADIO_TRANSMITTER_COVERAGE, null);
      console.log(error);
    }
  },
  async [GET_RADIO_BROADCASTING_SERVICE_COVERAGE](context, payload) {
    try {
      console.log(payload);
      const url = new URL(
        process.env.VUE_APP_API_URL + `api/v1/id/broadcast/radio/coverage/total`
      );
      for (let key in payload) {
        url.searchParams.append(key, payload[key]);
      }
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JwtServices.getToken()}`,
        },
      });
      const result = await response.json();
      if (result.error) throw result;
      context.commit(SET_RADIO_BROADCASTING_SERVICE_COVERAGE, result.data);
      return result;
    } catch (error) {
      context.commit(SET_RADIO_BROADCASTING_SERVICE_COVERAGE, null);
      console.log(error);
    }
  },

  async [GET_TOWER_RADIO_SUB_SERVICE](context) {
    try {
      const url = new URL(
        process.env.VUE_APP_API_URL + `api/v1/id/broadcast/radio/towers/sub-services`
        );
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JwtServices.getToken()}`,
          },
        });
        const result = await response.json();
      if (result.error) throw result.message;
      context.commit(SET_TOWER_RADIO_SUB_SERVICE, result.data?.sub_services || []);
      return response;
    } catch (error) {
      context.commit(SET_TOWER_RADIO_SUB_SERVICE, []);
      console.log(error);
    }
  },
  async [GET_COVERAGE_RADIO_RADIUS](context){
    try {
      const url = new URL(
        process.env.VUE_APP_API_URL + `api/v1/id/broadcast/radio/coverage/radii`
      );
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JwtServices.getToken()}`,
        },
      });  
      const result = await response.json();
      if (result.error) throw result;
      context.commit(SET_COVERAGE_RADIO_RADIUS, result.data?.radii || []);
      return result;
    } catch (error) {
      context.commit(SET_COVERAGE_RADIO_RADIUS, []);
      console.log(error);
    }
  }

};

const mutations = {
  [SET_TOWER_RADIO_BROADCASTING](state, payload) {
    state.towerRadioBroadcasting = payload;
  },
  [SET_ORGANIZERS_RADIO_BROADCASTING](state, payload) {
    state.organizerRadioBroadcasting = payload;
  },
  [SET_RADIO_TRANSMITTER_COVERAGE](state, payload) {
    state.radioTransmitterCoverage = payload;
  },
  [SET_RADIO_BROADCASTING_SERVICE_COVERAGE](state, payload) {
    state.radioBroadcastingServiceCoverage = payload;
  },
  [SET_TOWER_RADIO_SUB_SERVICE](state, payload) {
    state.towerRadioSubService = payload;
  },
  [SET_COVERAGE_RADIO_RADIUS](state, payload){
    state.coverageRadioRadius = payload
  }
};
export default {
  state,
  actions,
  mutations,
  getters,
};
