<template>
  <section class="single-page">
    <NavbarBigScreen />
      <div class="pmt-flex is-row wrap pl-5 pr-5 pt-2 pb-8 gap-4">
        <button
          v-for="(item, index) in subTab"
          :key="index"
          is="v-btn"
          @click="changeComponent(item.navigationTo)"
          :class="[`text-capitalize text-capitalize pl-6 pr-6`, activeItem == item.navigationTo ? 'active-link white--text': '']"
          rounded
          :outlined="true"
        >
          <!-- {{ $t("general.menu.fixed_broadband") }} -->
          {{ $t(item.name) }}
        </button>
        <!-- <v-btn
        @click="changeComponent(item.navigationTo)"
        >
            {{item.name}}z
        </v-btn> -->
      </div>
      <div class="pl-5 pr-5">

    <component :is="summary" />
      </div>
  </section>
</template>

<script>
import Pos from "./components/Pos/Pos.vue";
import RadioBroadcasting from "./components/RadioBroadcasting/RadioBroadcasting.vue";
import TVBroadcasting from "./components/TVBroadcasting/TVBroadcastingData.vue";
import NavbarBigScreen from "../../../layouts/NavbarFull/NavbarBigScreen.vue"
export default {
  components: {
    Pos,
    TVBroadcasting,
    RadioBroadcasting,
    NavbarBigScreen,
  },
  data: () => ({
    summary: Pos,
    activeItem: "Pos Data",
    subTab: [
      {
        name: "Pos Data",
        navigationTo: "Pos Data",
      },
      {
        name: "TV Broadcasting Data",
        navigationTo: "TV Broadcasting Data",
      },
      {
        name: "Radio Broadcasting Data",
        navigationTo: "Radio Broadcasting Data",
      },
    ],
  }),
  methods: {
    changeComponent(value) {
      if (value == "Pos Data") {
        this.activeItem = "Pos Data";
        this.summary = Pos;
      } else if (value == "TV Broadcasting Data") {
        this.activeItem = "TV Broadcasting Data";
        this.summary = TVBroadcasting;
      } else {
        this.activeItem = "Radio Broadcasting Data";
        this.summary = RadioBroadcasting;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.summary {
  height: 100%;
}
.gap-4{
    gap: 16px;
}
.active-link {
  box-shadow: unset !important;
  background: #134889 !important;
  border: 1px solid #134889 ;
}
</style>
