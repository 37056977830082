import ButtonNavigation from '../../../components/navigation/ButtonNavigation/ButtonNavigation.vue'

export default {
    components: {
        ButtonNavigation
    },
    data:()=>({
        name: 'risano',
        mainTab: [
            {
                name: "general.menu.data_post",
                path: '/dashboard/broadcasting/post'
            },
            {
                name: 'general.menu.data_television_broadcast',
                path:'/dashboard/broadcasting/television'
            },
            {
                name: 'general.menu.data_radio_broadcast',
                path: '/dashboard/broadcasting/radio'
            }
        ] 
    }),
}