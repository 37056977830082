import { render, staticRenderFns } from "./TicketingTelevision.vue?vue&type=template&id=fd7dacaa&scoped=true"
import script from "./TicketingTelevision.js?vue&type=script&lang=js&external"
export * from "./TicketingTelevision.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd7dacaa",
  null
  
)

export default component.exports