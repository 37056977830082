<template>
  <div class="pmt-flex is-row wrap pl-5 pr-5 pt-2 pb-8 gap-4">
    <button
      v-for="(item, index) in link"
      :key="index"
      is="v-btn"
      active-class="active-link white--text"
      :class="[
        `text-capitalize text-capitalize pl-6 pr-6`,
      ]"
      rounded
      :outlined="true"
      :to="{ path: item.path }"
    >
      <!-- {{ $t("general.menu.fixed_broadband") }} -->
      {{ $t(item.name) }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: Array,
      default: () => [],
    },
    isPage: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.gap-4{
    gap: 16px;
}
.active-link {
  box-shadow: unset !important;
  background: #134889 !important;
  border: 1px solid #134889 ;
}
</style>
