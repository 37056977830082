import { mapGetters, mapActions } from "vuex";
import VueApexCharts from "vue-apexcharts";
import {
  GET_PROVINCE,
  GET_CITY,
  GET_DISTRICT,
  GET_SUB_DISTRICT,
} from "../../../../../../store/modules/location.module";
import {
  GET_POS_OFFICE_NATIONAL,
  GET_ORGANIZERS_POS_NATIONAL,
} from "../../../../../../store/modules/pos.module";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    selectedProvince: 0,
    selectedCity: 0,
    selectedDistrict: 0,
    selectedSubDistrict: 0,
    searchProvince: "",
    searchCity: "",
    searchDistrict: "",
    searchSubDistrict: "",
    loadingTable: false,
    loadingChart: false,
    pagination: {
      page: 1,
      limit: 10,
      totalItems: 0,
      rowsPerPageItems: [10, 20, 30, 40, 500],
    },
    organizerPoscategories: [],
    OrganizerTypesPoscategories: [],
    organizerPosSeries: [],
    OrganizerTypesPosSeries: [],
    organizerPosChartTitle: "",
    chartOptions: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 2,
        },
      },
      colors: ["#CB4F4F", "#EA8426", "#CB1F4F", "#CB411F", "#F4F", "#F11"],
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      fill: {
        opacity: 1,
      },
      title: {
        text: "",
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#263238",
        },
      },
    },

    headers: [
      {
        text: "Nama Kantor",
        value: "nama_kanto",
      },
      {
        text: "Alamat",
        value: "alamat",
      },
      {
        text: "Jenis Kantor",
        value: "jenis",
      },
      {
        text: "Longitude",
        value: "bujur",
      },
      {
        text: "Latitude",
        value: "lintang",
      },
      {
        text: "Kode Pos",
        value: "kodedirian",
      },
      {
        text: "Penyelenggara",
        value: "penyelenggara",
      },
      {
        text: "Provinsi",
        value: "provinsi",
      },

      {
        text: "Kabupaten/Kota",
        value: "kab_kota",
      },
      {
        text: "Kecamatan",
        value: "kecamatan",
      },
    ],
    desserts: [],
  }),
  computed: {
    ...mapGetters([
      "item_province",
      "item_city",
      "item_district",
      "item_sub_district",
      "posOfficeNational",
      "paginationPosOfficeNational",
      "dateUpdatePosOfficeNational",
      "organizerPosNational",
    ]),
    organizerPostChartOptions: function () {
      return {
        ...this.chartOptions,
        xaxis: {
          categories: this.organizerPosNational.categories || [],
        },
        title: {
          ...this.chartOptions.title,
          text: `Total Penyelenggara Pos ${this.organizerPosChartTitle}`,
        },
      };
    },
  },
  methods: {
    ...mapActions([
      GET_PROVINCE,
      GET_CITY,
      GET_DISTRICT,
      GET_SUB_DISTRICT,
      GET_POS_OFFICE_NATIONAL,
      GET_ORGANIZERS_POS_NATIONAL,
    ]),
    initialize() {
      this.handleGetProvince();
      this.handleGetposOfficeNational();
      this.handleGetOrganizersPosNational();
    },
    handleGetProvince() {
      this[GET_PROVINCE]();
    },
    async handleGetposOfficeNational() {
      this.loadingTable = true;
      const { page, limit } = this.pagination;
      const params = {
        page,
        limit,
        ...(this.selectedProvince
          ? {
              province: this.item_province.find(
                (item) => item.id == this.selectedProvince
              ).id,
            }
          : {}),
        ...(this.selectedCity
          ? {
              city: this.item_city.find((item) => item.id == this.selectedCity)
                .code,
            }
          : {}),
        ...(this.selectedDistrict
          ? {
              district: this.item_district.find(
                (item) => item.id == this.selectedDistrict
              ).code,
            }
          : {}),
      };
      await this[GET_POS_OFFICE_NATIONAL](params);
      this.loadingTable = false;
    },
    async handleGetOrganizersPosNational() {
      this.loadingChart = true;
      const params = {
        ...(this.selectedProvince
          ? {
              province: this.item_province.find(
                (item) => item.id == this.selectedProvince
              ).id,
            }
          : {}),
        ...(this.selectedCity
          ? {
              city: this.item_city.find((item) => item.id == this.selectedCity)
                .code,
            }
          : {}),
      };
      await this[GET_ORGANIZERS_POS_NATIONAL](params);
      this.loadingChart = false;
      if (this.selectedCity) {
        this.organizerPosChartTitle = `${
          this.item_city.find((item) => item.id == this.selectedCity).name
        }, ${(this.organizerPosChartTitle = this.item_province.find(
          (item) => item.id == this.selectedProvince
        ).name)}`;
      } else if (this.selectedProvince) {
        this.organizerPosChartTitle = this.item_province.find(
          (item) => item.id == this.selectedProvince
        ).name;
      } else {
        this.organizerPosChartTitle = "";
      }
    },

    selectCity() {
      if (this.selectedProvince == 0) {
        this[GET_CITY](this.selectedProvince);
      } else {
        this.selectedCity = 0;
        this.selectedDistrict = 0;
        this.selectedSubDistrict = 0;
        this[GET_CITY](this.selectedProvince);
      }
    },
    selectDistrict() {
      this[GET_DISTRICT](this.selectedCity);
    },
    selectSubDistrict() {
      this[GET_SUB_DISTRICT](this.selectedDistrict);
    },
    changeLimitPagination(val) {
      this.pagination = {
        ...this.pagination,
        page: 1,
        limit: val,
      };
    },
    handleApply() {
      this.pagination = {
        ...this.pagination,
        page: 1,
      };

      this.handleGetOrganizersPosNational();
    },
    handleReset() {
      this.resetDataChart();
      this.resetDataTable();
    },
    resetDataChart() {
      this.selectedProvince = 0;
      this.selectedCity = 0;
      this.handleGetOrganizersPosNational();
    },
    resetDataTable() {
      this.selectedProvince = 0;
      this.selectedCity = 0;
      this.selectedDistrict = 0;
      this.pagination = {
        ...this.pagination,
        page: 1,
        limit: 10,
      };
    },
  },
  watch: {
    pagination: {
      handler() {
        this.handleGetposOfficeNational();
      },
      deep: true,
    },
  },
  mounted() {
    this.initialize();
  },
};
