import { render, staticRenderFns } from "./Sigmon.vue?vue&type=template&id=0a14f8fc&scoped=true"
import script from "./Sigmon.js?vue&type=script&lang=js&external"
export * from "./Sigmon.js?vue&type=script&lang=js&external"
import style0 from "./Sigmon.css?vue&type=style&index=0&id=0a14f8fc&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a14f8fc",
  null
  
)

export default component.exports