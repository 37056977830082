import JwtServices from "../../services/jwt.services";
import moment from "moment";
moment.locale("id");
// actions type
export const GET_POS_OFFICE_NATIONAL = "getPosOfficeNational";
export const GET_ORGANIZERS_POS_NATIONAL = "getOrganizersPosNational";
export const GET_OTHER_POS_OFFICE = "getOtherPosOffice";
export const GET_POS_COVERAGE = "getPosCoverage";

export const GET_ORGANIZERS_OTHER_POS = "getOrganizersOtherPos";
export const GET_ORGANIZERS_POS_COVERAGE = "getOrganizersPosCoverage";
export const GET_POS_COVERAGE_RADIUS = "getPosCoverageRadius"

// mutations type
export const SET_POS_OFFICE_NATIONAL = "setPosOfficeNational";
export const SET_ORGANIZERS_POS_NATIONAL = "setOrganizersPosNational";
export const SET_OTHER_POS_OFFICE = "setOtherPosOffice";
export const SET_POS_COVERAGE = "setPosCoverage";
export const SET_ORGANIZERS_OTHER_POS = "setOrganizersOtherPos";
export const SET_ORGANIZERS_POS_COVERAGE = "setOrganizersPosCoverage";
export const SET_POS_COVERAGE_RADIUS = "setPosCoverageRadius"

const state = {
  // pos National
  posOfficeNational: null,
  organizerPosNational: null,

  // pos Other
  otherPosOffice: null,
  organizerOtherPos: null,

  // coverage
  posCoverageRadius: [],
  posCoverage: null,
  organizerPosCoverage: null,
  error: null,
};

const getters = {
  posOfficeNational: (state) =>
    (state.posOfficeNational && state.posOfficeNational.offices)
      ? state.posOfficeNational.offices
      : [],
  paginationPosOfficeNational: (state) =>
    state.posOfficeNational ? state.posOfficeNational.pagination : {},
  dateUpdatePosOfficeNational: (state) =>
    state.posOfficeNational
      ? moment(state.posOfficeNational.date_updated).format("L")
      : "-",
  organizerPosNational: (state) => {
    if (state.organizerPosNational) {
      const { total_organizers } = state.organizerPosNational;
      let categories = [];
      let lpu = [];
      let komersil = [];
      for (let key in total_organizers) {
        categories[key] = total_organizers[key].region;
        lpu[key] = total_organizers[key].lpu || 0;
        komersil[key] = total_organizers[key].komersil || 0;
      }
      return {
        categories,
        series: [
          {
            name: "LPU",
            data: lpu,
          },
          {
            name: "Komersil",
            data: komersil,
          },
        ],
      };
    } else {
      return {
        categories: [],
        series: [],
      };
    }
  },

  //

  otherPosOffice: (state) =>
    state.otherPosOffice && state.otherPosOffice.offices
      ? state.otherPosOffice.offices
      : [],
  paginationOtherPosOffice: (state) =>
    state.otherPosOffice ? state.otherPosOffice.pagination : {},
  organizerOtherPos: () => {
    if (state.organizerOtherPos) {
      const { total_organizers } = state.organizerOtherPos;
      let categories = [];
      let series = [];
      for (let key in total_organizers) {
        categories[key] = total_organizers[key].region;
        for (let keyOrganizers in total_organizers[key].organizers) {
          if (
            !series.find(
              (item) =>
                item.name ===
                total_organizers[key].organizers[keyOrganizers].name
            )
          ) {
            series[keyOrganizers] = {
              name: total_organizers[key].organizers[keyOrganizers].name,
              data: [total_organizers[key].organizers[keyOrganizers].total],
            };
            console.log(series[keyOrganizers]);
          } else {
            const index = series.findIndex(
              (item) =>
                item.name ==
                total_organizers[key].organizers[keyOrganizers].name
            );
            series[index].data = [
              ...series[index].data,
              total_organizers[key].organizers[keyOrganizers].total,
            ];
          }
        }
      }
      return {
        categories,
        series,
      };
    } else {
      return {
        categories: [],
        series: [],
      };
    }
  },
  dateUpdateOtherPosOffice: (state) =>
    state.otherPosOffice
      ? moment(state.otherPosOffice.date_updated).format("L")
      : "-",

  // pos coverage
  posCoverage: (state) =>
    state.posCoverage && state.posCoverage.coverage
      ? state.posCoverage.coverage
      : [],
  dateUpdateOrganizerPosCoverage: (state) =>
    state.organizerPosCoverage
      ? moment(state.organizerPosCoverage.date_updated).format("L")
      : "-",
  paginationPosCoverage: (state) =>
    state.posCoverage ? state.posCoverage.pagination : {},
  organizerPosCoverage: (state) => {
    if (state.organizerPosCoverage) {
      const { regions } = state.organizerPosCoverage;
      let categories = [];
      let tempTotal = [];
      for (let key in regions) {
        categories[key] = regions[key].region ;
        tempTotal[key] = regions[key].total;
        // for (let keyOrganizers in total[key].organizers) {
        //   if (
        //     !series.find(
        //       (item) =>
        //         item.name ===
        //         total[key].organizers[keyOrganizers].name
        //     )
        //   ) {
        //     series[keyOrganizers] = {
        //       name: total[key].organizers[keyOrganizers].name,
        //       data: [
        //         total[key].organizers[keyOrganizers].total,
        //       ],
        //     };
        //     console.log(series[keyOrganizers]);
        //   } else {
        //     const index = series.findIndex(
        //       (item) =>
        //         item.name ==
        //         total[key].organizers[keyOrganizers].name
        //     );
        //     series[index].data = [
        //       ...series[index].data,
        //       total[key].organizers[keyOrganizers].total,
        //     ];
        //   }
        // }
      }
      return {
        categories,
        series: [
          {
            data: tempTotal,
          },
        ],
      };
    } else {
      return {
        categories: [],
        series: [],
      };
    }
  },
  posCoverageRadius:(state)=>{
    let temp = []
    for(let key in state.posCoverageRadius){
      temp[key] = {
        name: state.posCoverageRadius[key].split(' ').pop(),
        value: state.posCoverageRadius[key]
      }
    }
    return temp
  }
};
const actions = {
  [GET_POS_OFFICE_NATIONAL](context, payload) {
    const url = new URL(
      process.env.VUE_APP_API_URL + `api/v1/en/pos/offices/national`
    );
    for (let key in payload) {
      url.searchParams.append(key, payload[key]);
    }
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JwtServices.getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error != true) {
          context.commit(SET_POS_OFFICE_NATIONAL, res.data);
        } else {
          context.commit(SET_POS_OFFICE_NATIONAL, null);
          throw res;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  [GET_ORGANIZERS_POS_NATIONAL](context, payload) {
    const url = new URL(
      process.env.VUE_APP_API_URL +
        `api/v1/en/pos/offices/national/organizers/total`
    );
    for (let key in payload) {
      url.searchParams.append(key, payload[key]);
    }
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JwtServices.getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error != true) {
          console.log(res);
          context.commit(SET_ORGANIZERS_POS_NATIONAL, res.data);
        } else {
          context.commit(SET_ORGANIZERS_POS_NATIONAL, null);
          throw res;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  [GET_OTHER_POS_OFFICE](context, payload) {
    const url = new URL(
      process.env.VUE_APP_API_URL + `api/v2/id/pos/offices/others`
    );
    for (let key in payload) {
      url.searchParams.append(key, payload[key]);
    }
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JwtServices.getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error != true) {
          context.commit(SET_OTHER_POS_OFFICE, res.data);
        } else {
          context.commit(SET_OTHER_POS_OFFICE, null);
          throw res;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  [GET_ORGANIZERS_OTHER_POS](context, payload) {
    const url = new URL(
      process.env.VUE_APP_API_URL +
        `api/v2/id/pos/offices/others/organizers/total`
    );
    for (let key in payload) {
      url.searchParams.append(key, payload[key]);
    }
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JwtServices.getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error != true) {
          context.commit(SET_ORGANIZERS_OTHER_POS, res.data);
        } else {
          context.commit(SET_ORGANIZERS_OTHER_POS, null);
          throw res;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async [GET_POS_COVERAGE](context, payload) {
    try {
      const url = new URL(
        process.env.VUE_APP_API_URL + `api/v1/id/pos/coverage/national`
      );
      for (let key in payload) {
        url.searchParams.append(key, payload[key]);
      }
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JwtServices.getToken()}`,
        },
      });
      const result = await response.json();
      if (result.error) throw result.message;
      context.commit(SET_POS_COVERAGE, result.data);
      return result;
    } catch (error) {
      context.commit(SET_POS_COVERAGE, null);
      console.log(error);
    }
  },
  async [GET_ORGANIZERS_POS_COVERAGE](context, payload) {
    try {
      const url = new URL(
        process.env.VUE_APP_API_URL + `api/v1/id/pos/coverage/national/total`
      );
      for (let key in payload) {
        url.searchParams.append(key, payload[key]);
      }
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JwtServices.getToken()}`,
        },
      });
      const result = await response.json();
      if (result.error) throw result.message;
      context.commit(SET_ORGANIZERS_POS_COVERAGE, result.data);
      return result;
    } catch (error) {
      context.commit(SET_ORGANIZERS_POS_COVERAGE, null);
      console.log(error);
    }
  },
  async [GET_POS_COVERAGE_RADIUS](context) {
    try {
      const url = new URL(
        process.env.VUE_APP_API_URL + `api/v1/id/pos/coverage/national/radii`
      );
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JwtServices.getToken()}`,
        },
      });
      const result = await response.json();
      if (result.error) throw result.message;
      context.commit(SET_POS_COVERAGE_RADIUS, result.data.radii);
      return result;
    } catch (error) {
      context.commit(SET_POS_COVERAGE_RADIUS, []);
      console.log(error);
    }
  },
};

const mutations = {
  [SET_POS_OFFICE_NATIONAL](state, payload) {
    state.posOfficeNational = payload;
  },
  [SET_ORGANIZERS_POS_NATIONAL](state, payload) {
    state.organizerPosNational = payload;
  },
  [SET_OTHER_POS_OFFICE](state, payload) {
    state.otherPosOffice = payload;
  },
  [SET_ORGANIZERS_OTHER_POS](state, payload) {
    state.organizerOtherPos = payload;
  },
  [SET_POS_COVERAGE](state, payload) {
    state.posCoverage = payload;
  },
  [SET_ORGANIZERS_POS_COVERAGE](state, payload) {
    state.organizerPosCoverage = payload;
  },
  [SET_POS_COVERAGE_RADIUS](state, payload){
    state.posCoverageRadius = payload
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
