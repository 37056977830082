var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-row',[_c('router-link',{attrs:{"to":"/dashboard/potensi-desa"}},[_c('v-icon',{staticClass:"mt-8",attrs:{"size":"40","color":"black"}},[_vm._v("mdi-chevron-left")])],1),_c('v-col',[_c('h1',{staticClass:"my-4"},[_vm._v(" "+_vm._s(_vm.$t("general.menu.sub_district_potential_create"))+" ")])])],1),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('CardProfilDesa',{attrs:{"filter":_vm.filter}}),_c('v-expansion-panels',{attrs:{"multiple":"","disabled":_vm.panelDisabled},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.fieldList),function(data){return _c('v-expansion-panel',{key:data,ref:"formData",refInFor:true},[_c('v-expansion-panel-header',{ref:"category",refInFor:true,staticClass:"font-weight-bold",attrs:{"id":data.category_id}},[_vm._v(" "+_vm._s(data.name))]),_c('v-expansion-panel-content',[_c('div',{staticClass:"mt-2 mx-9 d-flex justify-start align-center flex-wrap"},[_vm._l((data.fields),function(item,index){return _c('div',{key:index},[(
                    item.is_calculated == false && item.data_type != 'select'
                  )?_c('v-text-field',{staticClass:"input-field text-h6",attrs:{"id":item.id,"value":item.value,"dense":"","outlined":"","rules":item.data_type == 'integer'
                      ? _vm.integerRules
                      : item.data_type == 'decimal'
                      ? _vm.decimalRules
                      : _vm.noRules,"label":item.name,"append-icon":item.unit},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},[_c('template',{slot:"append"},[_c('span',{staticClass:"data-unit"},[_vm._v(_vm._s(item.unit))])])],2):_vm._e(),(
                    item.is_calculated == true &&
                    item.is_used_by_calculation == true
                  )?_c('v-text-field',{staticClass:"input-field text-h6",attrs:{"id":item.id,"value":item.value,"dense":"","outlined":"","disabled":"","label":item.name,"append-icon":item.unit},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},[_c('template',{slot:"append"},[_c('span',{staticClass:"data-unit"},[_vm._v(_vm._s(item.unit))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"#169CD6"}},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.description)+" ")])],1)],2):_vm._e(),(item.data_type == 'select')?_c('v-select',{staticClass:"input-field text-h6",attrs:{"dense":"","outlined":"","id":item.id,"value":item.value,"items":item.option,"label":item.name}}):_vm._e()],1)}),_c('div',{staticClass:"mb-5",staticStyle:{"height":"1px","width":"100%","background-color":"#169cd6","border-radius":"5px"}}),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((data.fields),function(item,index){return _c('div',{key:index},[(
                      item.is_calculated == true &&
                      item.is_used_by_calculation == false
                    )?_c('v-text-field',{staticClass:"input-field text-h6",attrs:{"id":item.id,"disabled":"","value":'',"dense":"","label":item.name,"append-icon":item.unit}},[_c('template',{slot:"append"},[_c('span',{staticClass:"data-unit"},[_vm._v(_vm._s(item.unit))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"#169CD6"}},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.description)+" ")])],1)],2):_vm._e()],1)}),0)],2)])],1)}),1)],1)],1),_c('v-bottom-navigation',{attrs:{"app":"","color":"white","height":"5rem","fixed":""}},[_c('div',{staticClass:"bottom-navigation"},[_c('button',{staticClass:"cancel-button",attrs:{"to":""},on:{"click":_vm.cancelCreateData}},[_vm._v(" "+_vm._s(_vm.$t("ui.button_cancel"))+" ")]),_c('button',{staticClass:"create-button",on:{"click":_vm.submitData}},[_vm._v(" "+_vm._s(_vm.$t("ui.button_create_data"))+" ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }