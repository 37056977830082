import { mapGetters, mapActions } from "vuex";
import VueApexCharts from "vue-apexcharts";
import {
  GET_PROVINCE,
  GET_CITY,
  GET_DISTRICT,
} from "../../../../../../store/modules/location.module";
import {
  GET_RADIO_TRANSMITTER_COVERAGE,
  GET_RADIO_BROADCASTING_SERVICE_COVERAGE,
  GET_COVERAGE_RADIO_RADIUS
} from "../../../../../../store/modules/radio.module";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    selectedProvince: 0,
    selectedCity: 0,
    selectedDistrict: 0,
    selectedCoverageRadioRadius:"",
    selectedScope: "",
    searchProvince: "",
    searchCity: "",
    searchDistrict: "",
    searchCoverageRadioRadius:"",
    loadingTable: false,
    loadingChart: false,
    pagination: {
      page: 1,
      limit: 10,
      totalItems: 0,
      rowsPerPageItems: [10, 20, 30, 40, 500],
    },
    broadcastingServiceCoverageChartTitle: "",
    chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            borderRadius: 5,
          },
        },
        colors: ["#CB4F4F", "#EA8426", "#CB1F4F", "#CB411F", "#F4F", "#F11"],
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 1,
        },
        title: {
          text: "",
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263238",
          },
        },
      },
      headers: [
        {
          text: "Client Name",
          value: "clnt_name",
        },
        {
          text: "District",
          value: "district",
        },
        {
          text: "City",
          value: "city",
        },
        {
          text: "Province",
          value: "province",
        },
      ],
  }),
  computed: {
    ...mapGetters([
        "item_province",
        "item_city",
        "item_district",
        "radioTransmitterCoverage",
        "paginationRadioTransmitterCoverage",
        "dateUpdateRadioBroadcastingServiceCoverage",
        "radioBroadcastingServiceCoverage",
        "coverageRadioRadius"
      ]),
      radioBroadcastingServiceCoverageChartOptions: function () {
        return {
          ...this.chartOptions,
          xaxis: {
            categories: this.radioBroadcastingServiceCoverage.categories || [],
          },
          title: {
            ...this.chartOptions.title,
            text: `Cakupan Layanan Penyiaran ${this.broadcastingServiceCoverageChartTitle}`,
          },
        };
      },
  },
  methods: {
    ...mapActions([
        GET_PROVINCE,
        GET_CITY,
        GET_DISTRICT,
        GET_RADIO_TRANSMITTER_COVERAGE,
        GET_RADIO_BROADCASTING_SERVICE_COVERAGE,
        GET_COVERAGE_RADIO_RADIUS
      ]),
      async initialize() {
        this[GET_PROVINCE]();
        await this[GET_COVERAGE_RADIO_RADIUS]()
        this.selectedCoverageRadioRadius = this.coverageRadioRadius[0].value || ''
        this.handleGetRadioTransmitterCoverageData();
        this.handleGetRadioBroadcastingServiceCoverage();
      },
      async handleGetRadioTransmitterCoverageData() {
        this.loadingChart = true
        const { page, limit } = this.pagination;
        const params = {
          page,
          limit,
          ...(this.selectedProvince
            ? {
                province: this.item_province.find(
                  (item) => item.id == this.selectedProvince
                ).name,
              }
            : {}),
          ...(this.selectedCity
            ? {
                city: this.item_city.find((item) => item.id == this.selectedCity)
                  .name.replace('KAB. ', '').replace('KOTA ', ''),
              }
            : {}),
          ...(this.selectedDistrict
            ? {
                district: this.item_district.find(
                  (item) => item.id == this.selectedDistrict
                ).name,
              }
            : {}),
            ...(this.selectedCoverageRadioRadius ? {radius: this.selectedCoverageRadioRadius}:{})
        };
        await this[GET_RADIO_TRANSMITTER_COVERAGE](params);
        this.loadingChart = false
      },
      async handleGetRadioBroadcastingServiceCoverage() {
        this.loadingTable = true
        const params = {
          ...(this.selectedProvince
            ? {
                province: this.item_province.find(
                  (item) => item.id == this.selectedProvince
                ).name,
              }
            : {}),
          ...(this.selectedCity
            ? {
                city: this.item_city.find((item) => item.id == this.selectedCity)
                  .name.replace('KAB. ', '').replace('KOTA ', ''),
              }
            : {}),
            ...(this.selectedCoverageRadioRadius ? {radius: this.selectedCoverageRadioRadius}:{})
           ,
        };
        await this[GET_RADIO_BROADCASTING_SERVICE_COVERAGE](params);
        this.loadingTable = false
        if (this.selectedCity) {
          this.broadcastingServiceCoverageChartTitle = `${
            this.item_city.find((item) => item.id == this.selectedCity).name
          }, ${(this.broadcastingServiceCoverageChartTitle = this.item_province.find(
            (item) => item.id == this.selectedProvince
          ).name)}`;
        } else if (this.selectedProvince) {
          this.broadcastingServiceCoverageChartTitle = this.item_province.find(
            (item) => item.id == this.selectedProvince
          ).name;
        } else {
          this.broadcastingServiceCoverageChartTitle = "";
        }
      },
      selectCity() {
        if (this.selectedProvince == 0) {
          this[GET_CITY](this.selectedProvince);
        } else {
          this.selectedCity = 0;
          this.selectedDistrict = 0;
          this.selectedSubDistrict = 0;
          this[GET_CITY](this.selectedProvince);
        }
      },
      selectDistrict() {
        this[GET_DISTRICT](this.selectedCity);
      },
      changeLimitPagination(val) {
        this.pagination = {
          ...this.pagination,
          page: 1,
          limit: val,
        };
      },
      handleApply() {
        this.handleGetRadioTransmitterCoverageData();
        this.handleGetRadioBroadcastingServiceCoverage();
      },
      handleReset() {
        this.resetDataChart();
        this.resetDataTable();
      },
      resetDataChart() {
        this.selectedProvince = 0;
        this.selectedCity = 0;
        this.handleGetRadioBroadcastingServiceCoverage();
      },
      resetDataTable() {
        this.selectedProvince = 0;
        this.selectedCity = 0;
        this.selectedDistrict = 0;
        this.pagination = {
          ...this.pagination,
          page: 1,
          limit: 10,
        };
      },
  },
  watch: {
    pagination: {
      handler() {
        this.handleGetRadioTransmitterCoverageData();
      },
      deep: true,
    },
  },
  mounted() {
    this.initialize();
  },
};
