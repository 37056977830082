import JwtServices from "../../services/jwt.services";
import moment from "moment";
moment.locale("id");
// actions type
export const GET_TOWER_TV_BROADCASTING = "getTowerTVBroadcasting";
export const GET_ORGANIZERS_TV_BROADCASTING = "getOrganizersTVBroadcasting";
export const GET_TRANSMITTER_COVERAGE = "getTransmitterCoverage";
export const GET_BROADCASTING_SERVICE_COVERAGE =
  "getBroadcastingServiceCoverage";

// mutations type
export const SET_TOWER_TV_BROADCASTING = "setTowerTVBroadcasting";
export const SET_ORGANIZERS_TV_BROADCASTING = "setOrganizersTVBroadcasting";
export const SET_TRANSMITTER_COVERAGE = "setTransmitterCoverage";
export const SET_BROADCASTING_SERVICE_COVERAGE =
  "setBroadcastingServiceCoverage";

export const GET_TOWER_AREA = "getTowerArea";
export const SET_TOWER_AREA = "setTowerArea";

const state = {
  towerArea: [],
  towerTVBroadcasting: null,
  organizerTVBroadcasting: null,

  // coverage
  transmitterCoverage: null,
  broadcastingServiceCoverage: null,
  error: null,
};

const getters = {
  towerTVBroadcasting: (state) =>
    state.towerTVBroadcasting && state.towerTVBroadcasting.towers
      ? state.towerTVBroadcasting.towers
      : [],
  paginationTowerTVBroadcasting: (state) =>
    state.towerTVBroadcasting ? state.towerTVBroadcasting.pagination : {},
  dateUpdateOrganizerTVBroadcasting: (state) =>
    state.organizerTVBroadcasting
      ? moment(state.organizerTVBroadcasting.date_updated).format("L")
      : "-",
  organizerTVBroadcasting: (state) => {
    if (state.organizerTVBroadcasting) {
      const { regions } = state.organizerTVBroadcasting;

      let categories = [];
      let series = [];
      for (let key in regions) {
        categories[key] = regions[key].region;
        for (let keyOrganizers in regions[key].organizers) {
          if (
            !series.find(
              (item) =>
                item.name === regions[key].organizers[keyOrganizers].name
            )
          ) {
            series[keyOrganizers] = {
              name: regions[key].organizers[keyOrganizers].name,
              data: [regions[key].organizers[keyOrganizers].total],
            };
            console.log(series[keyOrganizers]);
          } else {
            const index = series.findIndex(
              (item) => item.name == regions[key].organizers[keyOrganizers].name
            );
            series[index].data = [
              ...series[index].data,
              regions[key].organizers[keyOrganizers].total,
            ];
          }
        }
      }
      return {
        categories,
        series,
      };
    } else {
      return {
        categories: [],
        series: [],
      };
    }
  },
  // coverage
  transmitterCoverage: (state) =>
    state.transmitterCoverage && state.transmitterCoverage.offices
      ? state.transmitterCoverage.offices
      : [],
  paginationTransmitterCoverage:(state)=> state.transmitterCoverage ? state.transmitterCoverage.pagination : {},
  dateUpdateTransmitterCoverage:(state)=> state.posCoverage ? moment(state.posCoverage.date_updated).format("L") : "-",
  broadcastingServiceCoverage:(state)=>{
    if (state.broadcastingServiceCoverage) {
      const { total_organizers_types } = state.broadcastingServiceCoverage;
      let categories = [];
      let series = [];
      for (let key in total_organizers_types) {
        categories[key] = total_organizers_types[key].region;
        for (let keyOrganizers in total_organizers_types[key].organizers) {
          if (
            !series.find(
              (item) =>
                item.name ===
                total_organizers_types[key].organizers[keyOrganizers].name
            )
          ) {
            series[keyOrganizers] = {
              name: total_organizers_types[key].organizers[keyOrganizers].name,
              data: [
                total_organizers_types[key].organizers[keyOrganizers].total,
              ],

            };
            console.log(series[keyOrganizers]);
          } else {
            const index = series.findIndex(
              (item) =>
                item.name ==
                total_organizers_types[key].organizers[keyOrganizers].name

            );
            series[index].data = [
              ...series[index].data,
              total_organizers_types[key].organizers[keyOrganizers].total,
            ];
          }
        }
      }
      return {
        categories,
        series,
      };
    } else {
      return {
        categories: [],
        series: [],
      };
    }
  },
  towerArea: (state) => {
      let area = [
        {
          value: '',
          name: 'All Area'
        }
      ]
      for (const value of state.towerArea) {
       area.push({
        value,
        name: value.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())
       })
      }
      return area
  },
};
const actions = {
 
  async [GET_TOWER_TV_BROADCASTING](context, payload) {
    try {
      const url = new URL(
        process.env.VUE_APP_API_URL + `api/v1/id/broadcast/television/towers`
      );
      for (let key in payload) {
        url.searchParams.append(key, payload[key]);
      }
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JwtServices.getToken()}`,
        },
      })
      const result = await response.json()
      if(result.error) throw result.error
      context.commit(SET_TOWER_TV_BROADCASTING, result.data)
      return response
    } catch (error) {
      console.log(error);
    }
   
  },
  [GET_ORGANIZERS_TV_BROADCASTING](context, payload) {
    const url = new URL(
      process.env.VUE_APP_API_URL +
        `api/v1/en/broadcast/television/towers/organizers/total`
    );
    for (let key in payload) {
      url.searchParams.append(key, payload[key]);
    }
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JwtServices.getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.error) {
          console.log(res);
          context.commit(SET_ORGANIZERS_TV_BROADCASTING, res.data);
        } else {
          throw res;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async [GET_TRANSMITTER_COVERAGE](context, payload) {
    try {
      const url = new URL(
        process.env.VUE_APP_API_URL + `api/v1/id/pos/coverage/national`
      );
      for (let key in payload) {
        url.searchParams.append(key, payload[key]);
      }
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JwtServices.getToken()}`,
        },
      });
      const result = await response.json();
      if (result.error) throw result;
      context.commit(SET_TRANSMITTER_COVERAGE, result.data);
      return result;
    } catch (error) {
      console.log(error);
    }
  },
  async [SET_BROADCASTING_SERVICE_COVERAGE](context, payload) {
    try {
      const url = new URL(
        process.env.VUE_APP_API_URL + `api/v1/id/pos/coverage/national/total`
      );
      for (let key in payload) {
        url.searchParams.append(key, payload[key]);
      }
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JwtServices.getToken()}`,
        },
      });
      const result = await response.json();
      if (result.error) throw result;
      context.commit(SET_BROADCASTING_SERVICE_COVERAGE, result.data);
      return result;
    } catch (error) {
      console.log(error);
    }
  },
  async [GET_TOWER_AREA](context) {
    try {
      const response = await fetch(
        process.env.VUE_APP_API_URL +
          "api/v1/id/broadcast/television/towers/areas",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JwtServices.getToken()}`,
          },
        }
      );
      const result = await response.json();
      if (result.err) throw result.err;
      context.commit(SET_TOWER_AREA, result.data.areas);
      return response
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  [SET_TOWER_TV_BROADCASTING](state, payload) {
    state.towerTVBroadcasting = payload;
  },
  [SET_ORGANIZERS_TV_BROADCASTING](state, payload) {
    state.organizerTVBroadcasting = payload;
  },
  [SET_TRANSMITTER_COVERAGE](state, payload) {
    state.transmitterCoverage = payload;
  },
  [SET_BROADCASTING_SERVICE_COVERAGE](state, payload) {
    state.broadcastingServiceCoverage = payload;
  },
  [SET_TOWER_AREA](state, payload) {
    state.towerArea = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
