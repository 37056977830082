<template>
  <div class="tnc">
    <div class="tnc-title">Terms and condition</div>
    <div class="tnc-text text-justify">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing nulla
      ullamcorper consequat sodales ac egestas. Id felis, felis libero lorem
      ultricies in phasellus. Proin lobortis dolor sed neque gravida. Orci fames
      consectetur felis elit viverra lacus elit. Potenti nibh ornare nisi varius
      mauris natoque facilisis. Nam interdum in auctor odio massa. Consectetur
      eu tellus velit ridiculus. Sit enim in sed vitae ultricies eu. Orci nibh
      ac ac amet. Et quam viverra gravida pellentesque id. Dolor sit nisl, lorem
      sodales id volutpat vitae est. Purus venenatis molestie egestas ac tempus
      aliquet sapien, cras enim. Lorem viverra nisi congue orci sed quis
      sollicitudin. Ultricies sed mauris etiam neque neque dui at. Ipsum
      tincidunt vel tincidunt amet lorem ullamcorper nec vivamus. Eu vel odio
      tellus aliquam tincidunt parturient velit mi venenatis. Etiam et tortor
      aliquet ut ultricies scelerisque eu aenean odio. Urna sit consectetur nibh
      purus. Mauris aliquam, nulla bibendum diam euismod nunc, sem. Cursus est
      facilisis parturient consequat, interdum aliquet est fermentum. Lectus
      aliquam egestas sit neque elementum. Elit sit diam enim vitae aenean. Eget
      pulvinar eu euismod etiam dignissim aenean duis. Nisi gravida egestas
      ullamcorper pulvinar pharetra, pharetra, in. In cursus et a tincidunt
      blandit tortor morbi.
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.tnc-title {
  color: #5e5873;
  font-weight: bold;
}
.tnc-text {
  color: #867e7e;
  margin-top: 20px;
}
</style>