import { mapGetters, mapActions } from "vuex";
import VueApexCharts from "vue-apexcharts";
import {
    GET_PROVINCE,
    GET_CITY,
    GET_DISTRICT,
    GET_SUB_DISTRICT,
  } from "../../../../../store/modules/location.module";
import {
    GET_ORGANIZERS_POS_NATIONAL,
    GET_ORGANIZERS_OTHER_POS,
    GET_ORGANIZERS_POS_COVERAGE,
    GET_POS_COVERAGE_RADIUS
  } from "../../../../../store/modules/pos.module";
export default {
  components:{
        apexchart: VueApexCharts
    },
    data:()=>({
        loadingChartPosNasional: false,
        loadingChartOtherPos: false,
        loadingChartPosCoverge: false,
        selectedProvince: 0,
        selectedCity: 0,
        selectedScope: "",
        searchProvince: "",
        searchCity: "",
        searchScope: "",
        organizerPosChartTitle: "",
        chartOptions: {
            chart: {
              type: "bar",
              height: 350,
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "55%",
                borderRadius: 2,
              },
            },
            colors: ["#CB4F4F", "#EA8426", "#CB1F4F", "#CB411F", "#F4F", "#F11"],
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },
            xaxis: {
              categories: [],
            },
            fill: {
              opacity: 1,
            },
            title: {
              text: "",
              align: "center",
              margin: 10,
              offsetX: 0,
              offsetY: 0,
              floating: false,
              style: {
                fontSize: "18px",
                fontWeight: "bold",
                fontFamily: undefined,
                color: "#263238",
              },
            },
          },
    }),
    computed:{
        ...mapGetters([
            "item_province",
            "item_city",
            "organizerPosNational",
            "organizerOtherPos",
            "organizerPosCoverage",
            "posCoverageRadius"
        ]),
        organizerPostChartOptions: function () {
            return {
              ...this.chartOptions,
              xaxis: {
                categories: this.organizerPosNational.categories || [],
              },
              title: {
                ...this.chartOptions.title,
                text: `Total Penyelenggara Pos ${this.organizerPosChartTitle}`,
              },
            };
          },
          organizerOtherPostChartOptions: function () {
            return {
              ...this.chartOptions,
              xaxis: {
                categories: this.organizerOtherPos.categories || [],
              },
              title:{
                ...this.chartOptions.title,
                text: `Total Pos Penyelenggara
                ${ this.organizerPosChartTitle}`
              }
            };
          },
          organizerPosCoverageChartOptions: function () {
            return {
              ...this.chartOptions,
              xaxis: {
                categories: this.organizerPosCoverage.categories || [],
              },
              title: {
                ...this.chartOptions.title,
                text: `Total Coverage Layanan Pos ${this.organizerPosChartTitle}`,
              },
            };
          },
          chartCoveragePosOptions: function(){
            return {
              ...this.chartOptions,
              xaxis: {
                categories: [],
              },
              title:{
                ...this.chartOptions.title,
                text: "Chart Coverage Pos"
              }
            };
          }
    },
    methods:{
        ...mapActions([
            GET_PROVINCE,
            GET_CITY,
            GET_DISTRICT,
            GET_SUB_DISTRICT,
            GET_ORGANIZERS_POS_NATIONAL,
            GET_ORGANIZERS_OTHER_POS,
            GET_ORGANIZERS_POS_COVERAGE,
            GET_POS_COVERAGE_RADIUS,
          ]),
          async initialize() {
            this.handleGetProvince();
            this.handleGetOrganizersPosNational();
            await this[GET_POS_COVERAGE_RADIUS]()
            this.selectedScope = this.posCoverageRadius[0]?.value || ''
            this.handleGetOrganizersPosCoverage()
          },
          handleGetProvince() {
            this[GET_PROVINCE]();
          },
          handleGetOrganizersPosCoverage(){
            this.loadingChartPosCoverge = true
            const params = {
              ...(this.selectedProvince
                ? {
                    province: this.selectedProvince
                  }
                : {}),
              ...(this.selectedCity
                ? {
                    city: this.item_city.find((item) => item.id == this.selectedCity)
                      .code,
                  }
                : {}),
                ...(this.selectedScope ? {radius: this.selectedScope}: {})
            };
            this[GET_ORGANIZERS_POS_COVERAGE](params)
            .then(()=>this.loadingChartPosCoverge = false)
          },
          handleGetOrganizersPosNational() {
            this.loadingChartPosNasional =  true
            this.loadingChartOtherPos =  true
           
            const params = {
              ...(this.selectedProvince
                ? {
                    province:  this.selectedProvince,
                  }
                : {}),
              ...(this.selectedCity
                ? {
                    city: this.item_city.find((item) => item.id == this.selectedCity)
                      .code,
                  }
                : {}),
            };
            this[GET_ORGANIZERS_POS_NATIONAL](params)
            .then(()=>this.loadingChartPosNasional = false)
            this[GET_ORGANIZERS_OTHER_POS](params)
            .then(()=>this.loadingChartOtherPos = false)
            if (this.selectedCity) {
              this.organizerPosChartTitle = `${
                this.item_city.find((item) => item.id == this.selectedCity).name
              }, ${(this.organizerPosChartTitle = this.item_province.find(
                (item) => item.id == this.selectedProvince
              ).name)}`;
            } else if (this.selectedProvince) {
              this.organizerPosChartTitle = this.item_province.find(
                (item) => item.id == this.selectedProvince
              ).name;
            }else{
              this.organizerPosChartTitle = "Nasional"
            }
          },
          selectCity() {
            if (this.selectedProvince == 0) {
              this[GET_CITY](this.selectedProvince);
            } else {
              this.selectedCity = 0;
              this.selectedDistrict = 0;
              this.selectedSubDistrict = 0;
              this[GET_CITY](this.selectedProvince);
            }
          },
          selectDistrict() {
            this[GET_DISTRICT](this.selectedCity);
          },
          handleApply(){
            this.handleGetOrganizersPosNational()
            this.handleGetOrganizersPosCoverage()
          },
          handleReset(){
            this.resetDataChart()
          },
          resetDataChart() {
            this.selectedProvince = 0;
            this.selectedCity = 0;
            this.handleGetOrganizersPosNational();
          },
    },
    mounted() {
        this.initialize();
      },
}