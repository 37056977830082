<template>
    <div id="chart">
        <v-row>
            <v-col lg="12">
                <strong>Sentiment</strong>
                <apexchart ref="realtimeChart" type="pie" height="350" :options="chartOptions" :series="series">
                </apexchart>
            </v-col>
        </v-row>
    </div>
</template>
  
<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import moment from "moment";

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        date_1: {
            default: '',
        },
        date_2: {
            default: '',
        }
    },
    data: function () {
        return {
            series: [44, 55, 13, 43, 22],
            fullDataMention: [],
            fullDataReach: [],
            chartOptions: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: ['Positive', 'Neutral', 'Negative'],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
            date_picker_social_chart_1: false,
            date_picker_social_chart_2: false,
        }
    },
    computed: {
        initialData: function () {
            const data = {
                facebook: [
                    {
                        date: "2023-10-28",
                        source_type: 1
                    },
                    {
                        date: "2023-10-28",
                        source_type: 80
                    },
                    {
                        date: "2023-10-28",
                        source_type: 140
                    },

                ],
                twitter: [
                    {
                        date: "2023-10-28",
                        source_type: 130
                    },
                    {
                        date: "2023-10-28",
                        source_type: 140
                    },
                    {
                        date: "2023-10-28",
                        source_type: 120
                    },
                ],
                instagram: [
                    {
                        date: "2023-10-28",
                        source_type: 23
                    },
                    {
                        date: "2023-10-28",
                        source_type: 190
                    },
                    {
                        date: "2023-10-28",
                        source_type: 290
                    },
                ],
                other: [
                    {
                        date: "2023-10-28",
                        source_type: 1
                    },
                    {
                        date: "2023-10-28",
                        source_type: 120
                    },
                    {
                        date: "2023-10-28",
                        source_type: 120
                    },
                ]
            }
            let newSeries = [
                {
                    data: data.facebook.map(item => { return item.source_type }),
                    name: 'Facebook',
                },
                {
                    data: data.twitter.map(item => { return item.source_type }),
                    name: 'Twitter',
                },
                {
                    data: data.instagram.map(item => { return item.source_type }),
                    name: 'Instagram',
                },
                {
                    data: data.other.map(item => { return item.source_type }),
                    name: 'Others',
                },
            ]
            return newSeries
        },
        socialStartDate: function () {
            if (this.date_1 == "") {
                return "All Time"
            } else {
                return `${moment(this.date_1).format("YYYY-MM-DD")}`;
            }
        },
        socialEndDate: function () {
            if (this.date_2 == "") {
                return "All Time"
            } else {
                return `${moment(this.date_2).format("YYYY-MM-DD")}`;
            }
        },
    },
    methods: {
        initialize() {
            this.getSocialChartMention();
            this.getSocialChartReach();
        },
        getSocialChartMention() {
            axios.get(process.env.VUE_APP_API_LOD_URL + "api/get_mention_sentiment_general_chart", {
                auth: {
                    username: process.env.VUE_APP_LOD_USERNAME,
                    password: process.env.VUE_APP_LOD_PASSWORD
                },
                params: {
                    start_date: this.date_1,
                    end_date: this.date_2,
                    category: "opsel"
                }
            }).then((response) => {
                console.log(response.data.data)
                // let newSeries = [
                //     {
                //         data: response.data.data.map(item => { return item.positive }),
                //         name: 'Positive',
                //     },
                //     {
                //         data: response.data.data.map(item => { return item.neutral }),
                //         name: 'Neutral',
                //     },
                //     {
                //         data: response.data.data.map(item => { return item.negative }),
                //         name: 'Negative',
                //     }
                // ]
                // this.series = newSeries
                // this.chartOptions = {
                //     ...this.chartOptionsMention,
                // }
            })
        },
        getRandomArbitrary() {
            return Math.floor(Math.random() * 35);
        },
        inputDate() {
            this.start_date = this.date_1;
            this.end_date = this.date_2;
        },
        submitSocial() {
            this.getSocialChartMention()
            this.getSocialChartReach()
        },
        resetSocial() {
            this.date_1 = ""
            this.date_2 = ""
            this.getSocialChartMention()
            this.getSocialChartReach()
        },
    },
    mounted() {
        this.initialize();
        // console.log(this.socialStartDate)
    }
};
</script>
  
<style></style>