import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import NavigationCellAndFo from "../../../../components/navigation/CellularAndFo/NavigationCellAndFo.vue";
import TabTicket from "../../../../components/TicketingManagement/PosAndBroadcasting/TabTicket.vue";
import TabProgress from "../../../../components/TicketingManagement/PosAndBroadcasting/TabProgress.vue";
import TabDetails from "../../../../components/TicketingManagement/PosAndBroadcasting/TabDetails.vue";
import JwtService from "../../../../services/jwt.services";
import {
  GET_START_DATE,
  GET_END_DATE,
} from "../../../../store/modules/qoe.module";
export default {
  components: {
    apexchart: VueApexCharts,
    NavigationCellAndFo,
  },
  data: () => ({
    userToken: null,
    loading: false,
    date: {
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      default: true,
      defaultTable: true,
      defaultText: "All Time",
    },
    copyNotification: false,
    dialogNew: {
      ticketDialog: false,
    },
    dialogConfirmationClose: false,
    dialogConfirmationReport: false,
    dialog: false,
    loadingDownload: false,
    whileDownload: false,
    date_picker_1: false,
    date_picker_2: false,
    date_picker_3: false,
    date_picker_4: false,
    chartOptions: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 3,
        },
      },
      colors: ["#CB4F4F", "#EA8426", "#CB1F4F", "#CB411F", "#F4F", "#F11"],
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      fill: {
        opacity: 1,
      },
      title: {
        text: "",
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#263238",
        },
      },
    },
    selectedData: null,
    source: [],
    // appId: 0,
    selectedUrgency: "",
    urgency: [
      {
        id: 1,
        name: "Low",
      },
      {
        id: 2,
        name: "Medium",
      },
       {
        id: 3,
        name: "Height",
      },
    ],
    selected: [],
    singleSelect: false,
    selectedPosTemp: 0,
    pos: [],
    selectedStatus: 0,
    status: [
      {
        id: 1,
        name: "Open",
      },
      {
        id: 2,
        name: "On Progress",
      },
      {
        id: 3,
        name: "Closed",
      },
    ],
    selectedTicket: null,
    headers: [
      {
        text: "No.Ticket",
        align: "start",
        sortable: false,
        value: "ticket_number",
      },
      { text: "Date", value: "open_at", sortable: false },
      { text: "Subject", value: "subject", sortable: false },
      { text: "Source", value: "app.name", sortable: false },
      {
        text: "Scope",
        value: "source_data[0].app_table.name",
        sortable: false,
      },
      {
        text: "Assigned to",
        value: "cell_operator.name",
        sortable: false,
      },
      { text: "Category", value: "category", sortable: false },
      { text: "Status", value: "ticket_status", sortable: false },
      {
        text: "Latitude",
        value: "source_data[0].user_information.full_address.latitude",
        sortable: false,
      },
      {
        text: "Longitude",
        value: "source_data[0].user_information.full_address.longitude",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    pagination: {
      page: 1,
      limit: 10,
      totalItems: 0,
      rowsPerPage: 10,
      rowsPerPageItems: [10, 20, 30, 40, 500],
    },
    data: [],
    dataDownload: [],
    tab: null,
    modal_items: [
      {
        tab: "ticket",
        content: TabTicket,
      },
      {
        tab: "progress",
        content: TabProgress,
      },
      {
        tab: "details",
        content: TabDetails,
      },
    ],
    closeat: null,
  }),
  methods: {
    ticketSummaryDialog() {
      // console.log("clicked", this.dialog.ticketDialog);
      this.dialogNew.ticketDialog = true;
    },
    inputDate(val) {
      if (val == "table") {
        this.date.defaultTable = false;
      } else {
        this.date.default = false;
      }
    },
    submitFilterDate() {
      if (this.date.default == true) {
        this.$swal({
          title: this.$t("ui.message.date_range"),
          confirmButtonColor: "#134889",
          icon: "info",
          showConfirmButton: true,
          timer: 3000,
        });
      } else {
        this.$store.dispatch(GET_START_DATE, this.date.startDate);
        this.$store.dispatch(GET_END_DATE, this.date.endDate);
        this.data = [];
        this.ticket_summary = [];
        this.getSummaryByOperators();
        this.initialize();
      }
    },
    resetFilterDate() {
      this.date.default = true;

      this.$store.dispatch(GET_START_DATE, "");
      this.$store.dispatch(GET_END_DATE, "");
      this.data = [];
      this.ticket_summary = [];
      this.getSummaryByOperators();
      this.initialize();
    },
    handleApply() {},
    handleReset() {},
    changeLimitPagination(val) {
      this.pagination = {
        ...this.pagination,
        page: 1,
        limit: val,
      };
    },
    closeModal() {
      this.dialog = false;
      this.data = [];
      this.selectedData = null;
      this.tab = null;
      this.initialize();
    },
    initialize() {
      this.getTicket();
    },
    viewDetail(item) {
      // console.log("viewDetail", item);
      this.dialog = true;
      this.selectedData = item;
      if (item.close_at != null) {
        this.closeat = new Date(item.close_at).toISOString().substr(0, 10);
      } else {
        this.closeat = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }

      // console.log(item);
    },
    getTicket() {
      this.loading = true;
      this.selected_opsel = this.selected_opsel_temp;
      const data = {
        limit: this.pagination.rowsPerPage,
        page: this.pagination.page,
        sort: "desc",
        start_date: this.start_date,
        end_date: this.end_date,
        // app_id: this.appId,
        urgency: this.selectedUrgency,
        cell_operator_id: 0,
        cell_operator_type: 2,
        ticket_status_id: this.selectedStatus,
        province_id: 0,
        city_id: 0,
        district_id: 0,
        sub_district_id: 0,
        search: "",
      };
      // console.log(data, "data payload");
      fetch(process.env.VUE_APP_API_URL + `api/v1/id/ticket`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.userToken}`,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          // console.log(result, "get ticket");
          this.loading_overlay = false;
          if (result.error == true) {
            this.loading = false;
            this.data = [];
            this.$swal({
              text: result.message,
              showConfirmButton: true,
              confirmButtonColor: "#134889",
              timer: 3000,
              icon: "error",
            });
          }
          this.data = result.data.data;
          this.dataDownload = [];
          result.data.data.forEach((item) => {
            this.dataDownload.push({
              ticket_number: item.ticket_number ? item.ticket_number : "-",
              date: item.open_at ? item.open_at : "-",
              subject: item.subject ? item.subject : "-",
              source: item.app.name ? item.app.name : "-",
              scope: item.source_data[0].app_table.name
                ? item.source_data[0].app_table.name
                : "-",
              assigned_to: item.cell_operator.name
                ? item.cell_operator.name
                : "-",
              category: item.category ? item.category : "-",
              status: item.ticket_status.name ? item.ticket_status.name : "-",
              cell_operator_name: item.source_data[0].cell_operator.name
                ? item.source_data[0].cell_operator.name
                : "-",
              connection_type: item.source_data[0].connection_type
                ? item.source_data[0].connection_type
                : "-",
              download_speed: item.source_data[0].download_speed
                ? item.source_data[0].download_speed
                : "-",
              upload_speed: item.source_data[0].upload_speed
                ? item.source_data[0].upload_speed
                : "-",
              latency: item.source_data[0].latency
                ? item.source_data[0].latency
                : "-",
              jitter: item.source_data[0].jitter
                ? item.source_data[0].jitter
                : "-",
              loss: item.source_data[0].loss ? item.source_data[0].loss : "-",
              province:
                item.province && item.province.name != null
                  ? item.province.name
                  : "-",
              city: item.city && item.city.name != null ? item.city.name : "-",
              district:
                item.district && item.district.name != null
                  ? item.district.name
                  : "-",
              subdistrict:
                item.district && item.sub_district.name != null
                  ? item.sub_district.name
                  : "-",
              timestamp: item.source_data[0].test_at
                ? item.source_data[0].test_at
                : "-",
              source_montel: item.source_data[0].source
                ? item.source_data[0].source
                : "-",
              account: item.source_data[0].account
                ? item.source_data[0].account
                : "-",
              followers: item.source_data[0].followers
                ? item.source_data[0].followers
                : "-",
              content: item.source_data[0].content
                ? item.source_data[0].content
                : "-",
              url: item.source_data[0].url ? item.source_data[0].url : "-",
            });
          });
          // this.selected_opsel = 0;
          // this.selected_opsel_temp = 0;
          // this.selected_status = 0;
          // this.appId = 0;
        
          this.pagination.page = result.data.pagination.current_page;
          this.pagination.totalItems = result.data.pagination.total_page;

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    copyLatlong(item) {
      // console.log(item, "item");
      this.copyNotification = true;
      // console.log(this.copyNotification, "copyNotification");
      const lat = item.source_data[0].user_information.full_address.latitude;
      const long = item.source_data[0].user_information.full_address.longitude;
      const latlong = long + ", " + lat;
      navigator.clipboard.writeText(latlong);
    },
    getCurrentDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();
      this.date.endDate = yyyy + "-" + mm + "-" + dd;
      this.dateCurrent = yyyy + "-" + mm + "-" + dd;
    },
    closeTicket(value) {
      this.loading_overlay = true;
      let closeAt = moment().format("YYYY-MM-DD HH:mm:ss");
      let formData = new FormData();

      formData.append("id", value.id);
      formData.append("cell_operator_id", value.cell_operator_id);
      formData.append("province_id", value.province_id);
      formData.append("city_id", value.city_id);
      formData.append("district_id", value.district_id);
      formData.append("sub_district_id", value.sub_district_id);
      formData.append("ticket_status_id", 3);
      formData.append("category", value.category);
      formData.append("description", value.province_id);
      formData.append("subject", value.subject);
      formData.append("ticket_number", value.ticket_number);
      formData.append("close_at", closeAt);
      formData.append("attachment", value.attachment);

      fetch(process.env.VUE_APP_API_URL + `api/v1/id/ticket/update`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ` + this.user_token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          this.loading_overlay = false;
          if (result.error == true) {
            this.$swal({
              text: result.message,
              timer: 1000,
              showConfirmButton: false,
              icon: "error",
            });
          } else {
            this.$swal({
              text: result.message,
              timer: 1000,
              showConfirmButton: false,
              icon: "success",
            });
            this.dialogConfirmationClose = false;
            this.selectedTicket = null;
            this.initialize();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
	sendReportTicket(value) {
		this.loading_overlay = true;

		var ids = [];
		if (value.length == undefined) {
			ids.push(value.id);
		} else {
			for (const iterator of value) {
				ids.push(iterator.id);
			}
		}

		let data = {
			ids: JSON.stringify(ids),
		};

		fetch(
			process.env.VUE_APP_API_URL +
				`api/v2/${this.user.language}/ticket/send-ticket-report`,
			{
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ` + this.user_token,
				},
			}
		)
			.then((response) => response.json())
			.then((result) => {
				// console.log(result);
				this.loading_overlay = false;
				if (result.error == true) {
					this.$swal({
						text: result.message,
						timer: 1000,
						showConfirmButton: false,
						icon: "error",
					});
				} else {
					this.$swal({
						text: result.message,
						timer: 1000,
						showConfirmButton: false,
						icon: "success",
					});
					this.dialog_confirmation_report = false;
					this.selected_ticket = null;
					this.initialize();
				}
			})
			.catch((err) => {
				console.log(err);
			});
	},
  },
  filters: {
    moment: function (date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  computed: {
    summaryCard() {
      return {
        total_ticket: 0,
        open_ticket: 0,
        progress_ticket: 0,
        close_ticket: 0,
      };
    },
    organizerPostChartOptions: function () {
      return {
        ...this.chartOptions,
        xaxis: {
          categories: [],
          // categories: ['Bali-NTT', 'Jawa', 'Kalimantan', 'Maluku-Papua', 'Sulawesi', 'Sumatera']
        },
        title: {
          ...this.chartOptions.title,
          text: `Penyelenggara Pos`,
        },
      };
    },
    start_date: function () {
      return this.$store.getters.start_date;
    },
    end_date: function () {
      return this.$store.getters.end_date;
    },
  },
  mounted() {
    this.user = JwtService.getUser();
    this.$store.dispatch(GET_START_DATE, "");
    this.$store.dispatch(GET_END_DATE, "");
    this.initialize();
    setTimeout(() => {
      this.getSummaryByOperators();
    }, 100);
  },
  created() {
    this.userToken = JwtService.getToken();

    // date_1 = three months ago
    this.date.startDate = moment().subtract(3, "months").format("YYYY-MM-DD");

    this.getCurrentDate();
    // this.getThreeMontsAgoDate();
  },
};
