var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"single-page p-0"},[_c('div',{staticClass:"title mt-5 ml-5"},[_c('h4',[_vm._v(_vm._s(_vm.$t("general.menu.alarm_dashboard")))])]),_c('NavigationCellAndFo',{attrs:{"link":{
      cellular: '/dashboard/alarm-dashboard',
      fo: '/dashboard/fo/alarm-dashboard',
    },"is-page":"Operator Cellular"}}),_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"lg":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"solo":"","dense":"","prepend-inner-icon":"mdi-calendar","placeholder":"Start Date","hint":_vm.$t('ui.field.start_date'),"persistent-hint":"","readonly":""},model:{value:(
              _vm.filter.date.default != true
                ? _vm.filterComputed.preview.startDate
                : _vm.filter.date.defaultText
            ),callback:function ($$v) {_vm.$set(_vm.filter.date.default != true
                ? _vm.filterComputed.preview.startDate
                : _vm.filter.date, "defaultText", $$v)},expression:"\n              filter.date.default != true\n                ? filterComputed.preview.startDate\n                : filter.date.defaultText\n            "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_picker_1),callback:function ($$v) {_vm.date_picker_1=$$v},expression:"date_picker_1"}},[_c('v-date-picker',{on:{"input":_vm.filterDate},model:{value:(_vm.filter.date.startDate),callback:function ($$v) {_vm.$set(_vm.filter.date, "startDate", $$v)},expression:"filter.date.startDate"}})],1),_c('v-menu',{staticClass:"ml-5",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"solo":"","dense":"","prepend-inner-icon":"mdi-calendar","placeholder":"End Date","hint":_vm.$t('ui.field.end_date'),"persistent-hint":"","readonly":""},model:{value:(
              _vm.filter.date.default != true
                ? _vm.filterComputed.preview.endDate
                : _vm.filter.date.defaultText
            ),callback:function ($$v) {_vm.$set(_vm.filter.date.default != true
                ? _vm.filterComputed.preview.endDate
                : _vm.filter.date, "defaultText", $$v)},expression:"\n              filter.date.default != true\n                ? filterComputed.preview.endDate\n                : filter.date.defaultText\n            "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_picker_2),callback:function ($$v) {_vm.date_picker_2=$$v},expression:"date_picker_2"}},[_c('v-date-picker',{on:{"input":_vm.filterDate},model:{value:(_vm.filter.date.endDate),callback:function ($$v) {_vm.$set(_vm.filter.date, "endDate", $$v)},expression:"filter.date.endDate"}})],1)],1),_c('v-col',{attrs:{"lg":"1"}},[_c('v-btn',{staticClass:"text-capitalize white--text",attrs:{"elevation":"0","width":"100%","color":"#1976D2"},on:{"click":_vm.submitFilter}},[_vm._v(" "+_vm._s(_vm.$t("ui.button_submit"))+" ")])],1),_c('v-col',{attrs:{"lg":"1"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"elevation":"0","width":"100%"},on:{"click":_vm.resetFilter}},[_vm._v("Reset")])],1)],1),_c('v-row',{staticClass:"mt-10 pa-5"},[_c('v-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"font-weight-bold text-center",staticStyle:{"font-size":"20px"}},[_vm._v(" Critical ")])]),_vm._l((_vm.critical),function(item,i){return _c('v-col',{key:i,attrs:{"lg":"4"}},[_c('v-card',{staticClass:"pa-5",attrs:{"height":"100%"}},[_c('div',{staticClass:"font-weight-bold d-flex justify-center align-center"},[_c('img',{attrs:{"width":"50px","height":"40px","src":_vm.pmt_url + item.opsel.logo,"alt":item.opsel.name}}),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(item.opsel.name))])]),_c('apexchart',{attrs:{"type":"donut","options":_vm.optionsCritical,"series":item.data == null
              ? [0, 0]
              : [item.data.active_alarm, item.data.closed_alarm]}}),_c('v-card',{staticClass:"pa-5 mt-5",attrs:{"flat":"","outlined":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('div',[_vm._v("Hardware")]),_c('div',[_vm._v(" "+_vm._s(item.data == null ? 0 : item.data.hardware)+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('div',[_vm._v("Transmission")]),_c('div',[_vm._v(" "+_vm._s(item.data == null ? 0 : item.data.transmission)+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('div',[_vm._v("Power Failure")]),_c('div',[_vm._v(" "+_vm._s(item.data == null ? 0 : item.data.power_failure)+" ")])])],1)],1),_c('div',{staticClass:"pa-5 text-center cyan--text font-weight-bold details",on:{"click":function($event){return _vm.seeDetails(item)}}},[_vm._v(" "+_vm._s(_vm.$t("ui.button_details"))+" ")])],1)],1)})],2),_c('hr'),_c('v-row',{staticClass:"mt-10 pa-5"},[_c('v-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"font-weight-bold text-center",staticStyle:{"font-size":"20px"}},[_vm._v(" Major ")])]),_vm._l((_vm.major),function(item,i){return _c('v-col',{key:i,attrs:{"lg":"4"}},[_c('v-card',{staticClass:"pa-5",attrs:{"height":"100%"}},[_c('div',{staticClass:"font-weight-bold d-flex justify-center align-center"},[_c('img',{attrs:{"width":"50px","height":"40px","src":_vm.pmt_url + item.opsel.logo,"alt":item.opsel.name}}),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(item.opsel.name))])]),_c('apexchart',{attrs:{"type":"donut","options":_vm.optionsMajor,"series":item.data == null
              ? [0, 0]
              : [item.data.active_alarm, item.data.closed_alarm]}}),_c('v-card',{staticClass:"pa-5 mt-5",attrs:{"flat":"","outlined":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('div',[_vm._v("Hardware")]),_c('div',[_vm._v(" "+_vm._s(item.data == null ? 0 : item.data.hardware)+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('div',[_vm._v("Transmission")]),_c('div',[_vm._v(" "+_vm._s(item.data == null ? 0 : item.data.transmission)+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('div',[_vm._v("Power Failure")]),_c('div',[_vm._v(" "+_vm._s(item.data == null ? 0 : item.data.power_failure)+" ")])])],1)],1),_c('div',{staticClass:"pa-5 text-center cyan--text font-weight-bold details",on:{"click":function($event){return _vm.seeDetails(item)}}},[_vm._v(" "+_vm._s(_vm.$t("ui.button_details"))+" ")])],1)],1)})],2),_c('hr'),_c('v-row',{staticClass:"mt-10 pa-5"},[_c('v-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"font-weight-bold text-center",staticStyle:{"font-size":"20px"}},[_vm._v(" Minor ")])]),_vm._l((_vm.minor),function(item,i){return _c('v-col',{key:i,attrs:{"lg":"4"}},[_c('v-card',{staticClass:"pa-5",attrs:{"height":"100%"}},[_c('div',{staticClass:"font-weight-bold d-flex justify-center align-center"},[_c('img',{attrs:{"width":"50px","height":"40px","src":_vm.pmt_url + item.opsel.logo,"alt":item.opsel.name}}),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(item.opsel.name))])]),_c('apexchart',{attrs:{"type":"donut","options":_vm.optionsMinor,"series":item.data == null
              ? [0, 0]
              : [item.data.active_alarm, item.data.closed_alarm]}}),_c('v-card',{staticClass:"pa-5 mt-5",attrs:{"flat":"","outlined":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('div',[_vm._v("Hardware")]),_c('div',[_vm._v(" "+_vm._s(item.data == null ? 0 : item.data.hardware)+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('div',[_vm._v("Transmission")]),_c('div',[_vm._v(" "+_vm._s(item.data == null ? 0 : item.data.transmission)+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('div',[_vm._v("Power Failure")]),_c('div',[_vm._v(" "+_vm._s(item.data == null ? 0 : item.data.power_failure)+" ")])])],1)],1),_c('div',{staticClass:"pa-5 text-center cyan--text font-weight-bold details",on:{"click":function($event){return _vm.seeDetails(item)}}},[_vm._v(" "+_vm._s(_vm.$t("ui.button_details"))+" ")])],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }