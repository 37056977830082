<template>
  <div class="post">
    <div class="font-weight-bold mt-5" style="font-size: 24px">
      infografis {{ $t("general.menu.data_post") }}
    </div>
    <p v-if="activeItem === 'Pos Service Coverage'" class="text-grey mt-4">Disclaimer: Data Prediksi Coverage Layanan Diolah Berdasarkan Data Sebaran Cabang/Agen Tahun 2022 dengan batasan untuk cakupan layanan adalah radius 200 meter akses jalan yang tersedia untuk jangkauan 2.5km, 5km, 10km, dari titik cabang/agen.</p>
    <v-row class="mt-5">
      <v-col cols="auto" v-for="(item, index) in subTab" :key="index">
        <v-btn
          width="100%"
          class="text-capitalize text-grey"
          height="50"
          rounded
          elevation="0"
          @click="changeComponent(item.navigationTo)"
          :class="[
            `text-capitalize`,
            activeItem == item.navigationTo ? `active-btn` : ``,
          ]"
        >
          {{ $t(item.name) }}
        </v-btn>
      </v-col>
    </v-row>

    <!-- Component Data Post Choice -->
    <component :is="post" />
  </div>
</template>

<script>
import DistributionPos from "./components/DistributionPos/DistributionPos.vue";
import OtherPosSpreads from "./components/OtherPosSpreads/OtherPosSpreads.vue";
import PosServiceCoverage from "./components/PosServiceCoverage/PosServiceCoverage.vue";

export default {
  components: {
    DistributionPos,
    OtherPosSpreads,
    PosServiceCoverage,
  },
  data: () => ({
    post: DistributionPos,
    activeItem: "Distribution of Pos Operators",
    subTab: [
      {
        name: "ui.button_distribution_postal_operators",
        navigationTo: "Distribution of Pos Operators",
      },
      {
        name: "ui.button_other_post_spreads",
        navigationTo: "Other Pos Spreads",
      },
      {
        name: "ui.button_postal_service_coverage",
        navigationTo: "Pos Service Coverage",
      },
    ],
  }),
  methods: {
    changeComponent(value) {
      // console.log(value);
      if (value == "Distribution of Pos Operators") {
        this.activeItem = "Distribution of Pos Operators";
        this.post = DistributionPos;
      } else if (value == "Other Pos Spreads") {
        this.activeItem = "Other Pos Spreads";
        this.post = OtherPosSpreads;
      } else {
        this.activeItem = "Pos Service Coverage";
        this.post = PosServiceCoverage;
      }
    },
  },
};
</script>

<style lang="scss">
.post {
  height: 100%;
}

.active-btn {
  background-color: #ffba3b !important;
  color: white !important;
}
.text-grey {
  color: #867e7e;
}
.pos-chart {
  .apexcharts-menu-item.exportCSV {
    display: none !important;
  }
}
</style>
