import VueApexCharts from "vue-apexcharts";
import { mapGetters, mapActions } from "vuex";
import { GET_ORGANIZERS_TV_BROADCASTING, GET_TOWER_AREA} from "../../../../../store/modules/tv.module"
export default{
    components:{
        apexchart: VueApexCharts
    },
    data: () => ({

        selectedArea:"",
        searchArea:"",
        organizerTVBroadcastingChartTitle: "",
        chartOptions: {
          chart: {
            type: "bar",
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              borderRadius: 3,
            },
          },
          colors: ["#CB4F4F", "#EA8426", "#CB1F4F", "#CB411F","#F4F", '#F11'],
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: [],
          },
          fill: {
            opacity: 1,
          },
          title: {
            text: "",
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "18px",
              fontWeight: "bold",
              fontFamily: undefined,
              color: "#263238",
            },
          },
        },
        desserts: [],
      }),
      computed: {
        ...mapGetters([
          "organizerTVBroadcasting",
          "towerArea"
        ]),
        organizerTowerTVChartOptions:function(){
          return {
            ...this.chartOptions,
            xaxis:{
              categories:this.organizerTVBroadcasting.categories || []
              // categories: ['Bali-NTT', 'Jawa', 'Kalimantan', 'Maluku-Papua', 'Sulawesi', 'Sumatera']
            },
            title: {
              ...this.chartOptions.title,
              text: `Total Penyelenggara Penyiaran TV ${this.organizerTVBroadcastingChartTitle}`,
            },
          }
        },
        broadcastingCoveragePercentageChartOptions: function(){
            return {
              ...this.chartOptions,
              chart:{
                type:"donut",
                height: 360,
              },
              xaxis: {
                categories: ['test1', 'test2', 'test3', 'test4'],
              },
              title:{
                ...this.chartOptions.title,
                text: "Persentase Cakupan Layanan Penyiaran"
              }
            };
          }
      },
      methods: {
        ...mapActions([GET_ORGANIZERS_TV_BROADCASTING, GET_TOWER_AREA]),
        initialize() {
          this[GET_TOWER_AREA]()
          this.handleGetOrganizersTVBroadcasting()
        },
        handleGetOrganizersTVBroadcasting(){
          const params = {
            ...(this.selectedArea ? {area: this.selectedArea}: {}),
          }
          this[GET_ORGANIZERS_TV_BROADCASTING](params)
          this.organizerTVBroadcastingChartTitle = this.selectedArea ? this.selectedArea.toLowerCase().replace(/\b\w/g, s => s.toUpperCase()) : 'Nasional-Regional Besar'
        },
        handleApply(){
            this.handleGetOrganizersTVBroadcasting()
        },
        resetDataChart(){
          this.selectedArea = ""
          this.handleGetOrganizersTVBroadcasting()
        },
       
      },
      mounted() {
        this.initialize();
      },
}