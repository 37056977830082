<template>
    <div id="social-mention-list">
        <v-card class="pa-4 montel-card">
            <SkeletonLoader v-if="loading" />
            <div class="list-mention" id="list-mention" v-else style="overflow-y: scroll; max-height: 100em;">
                <v-card class="mb-3 pa-3 mx-1" v-for="(item, index) in historyList" :key="index">
                    <div class="d-flex align-center mb-3">
                        <div class="badge rounded bg-label-success p-1">
                            <font-awesome-icon v-if="item.source_type === 'twitter'" style="color: black;"
                                icon="fa-brands fa-x-twitter" />
                            <v-icon v-else
                                v-bind:style="[item.source_type === `facebook` ? { color: `blueviolet` } : { color: `red` }]">mdi-{{ item.source_type }}</v-icon>
                        </div>
                        <div class="me-2 ms-2 text-body h5 mb-0 font-weight-bold">
                            {{ item.source_name }}
                            <div class="font-weight-light">{{ item.source_type }}.com | {{ item.published_date }} |
                                {{ item.country }}</div>
                        </div>
                        <v-dialog width="80%" v-model="dialog[index]">
                            <template v-slot:activator="{ on, attrs }">
                                <div @click="setupCreateTicket(item)" v-bind="attrs" v-on="on"
                                    class="ml-auto d-flex justify-end w-100">
                                    <v-btn class="mr-4" active-class="active-link"
                                        style="color: black;">{{ $t("ui.button_create_ticket") }}</v-btn>
                                </div>
                            </template>
                            <v-card class="pa-5">
                                <div class="font-weight-bold">
                                    {{ $t("ui.button_create_ticket") }}
                                </div>
                                <v-row class="mt-10">
                                    <v-col lg="1" class="font-weight-bold"> Source </v-col>
                                    <v-col lg="5" class="filter-text">
                                        {{ app_name }}
                                    </v-col>
                                    <v-col lg="1" class="font-weight-bold">Status</v-col>
                                    <v-col lg="5" class="filter-text">Open</v-col>
                                    <v-col lg="1" class="font-weight-bold mt-2">Date</v-col>
                                    <v-col lg="5" class="filter-text">
                                        <v-text-field v-model="date_open" dense prepend-inner-icon="mdi-calendar" readonly
                                            outlined disabled></v-text-field>
                                    </v-col>
                                    <v-col lg="1" class="font-weight-bold mt-2">Assign to</v-col>
                                    <v-col lg="5" class="filter-text">
                                        <v-select v-model="selected_cell_operator" :value="selected_data.cell_operator_id"
                                            :items="opsel" item-value="id" item-text="name" dense outlined
                                            placeholder="Assign To"></v-select>
                                    </v-col>
                                    <v-col lg="1" class="font-weight-bold mt-2">Category</v-col>
                                    <v-col lg="5" class="filter-text">
                                        <v-select v-model="category" :items="list_category" item-value="name"
                                            item-text="name" dense outlined placeholder="Category"></v-select>
                                    </v-col>
                                    <v-col lg="1" class="font-weight-bold mt-2">Subject</v-col>
                                    <v-col lg="5" class="filter-text">
                                        <v-text-field v-model="subject" dense outlined
                                            placeholder="Tidak bisa internet"></v-text-field>
                                    </v-col>
                                    <v-col lg="1" class="font-weight-bold mt-2">Description</v-col>
                                    <v-col lg="5" class="filter-text">
                                        <v-textarea v-model="description" dense outlined></v-textarea>
                                    </v-col>

                                    <v-col lg="1" class="font-weight-bold mt-2">Attachment</v-col>
                                    <v-col lg="5" class="filter-text">
                                        <v-file-input show-size dense outlined v-model="attachment"
                                            placeholder="Upload file"></v-file-input>
                                    </v-col>
                                    <v-col lg="12" class="d-flex justify-end">
                                        <v-btn @click="submitCreateTicket" elevation="0" class="mr-5 white--text text-capitalize" color="#3C7FBE">Buat
                                            Ticket</v-btn>
                                        <v-btn outlined class="text-capitalize" elevation="0"
                                            @click="dialog = []">Cancel</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-dialog>
                    </div>
                    <a :href="item.url" target="_blank" class="card-body mb-4" style="color: black;">
                        <p class="mb-3">
                            {{ item.description }}
                        </p>
                        <div class="d-flex align-items-center pt-1">
                            <div class="d-flex align-items-center">
                                <ul class="list-unstyled d-flex align-items-center avatar-group ps-0 mb-0">
                                    <div class="small text-muted ">
                                        <v-icon class="me-1 button-hover-blue">mdi-eye</v-icon>{{ item.direct_reach }}
                                    </div>
                                </ul>
                            </div>
                            <div class="ml-2">
                                <v-badge
                                    :color="item.tone === `negative` ? `error` : item.tone === `positive` ? `success` : `warning`"
                                    :content="item.tone" inline></v-badge>
                            </div>
                        </div>
                    </a>
                </v-card>
            </div>
            <div class="w-full d-flex">
                <div class="mx-auto mt-2 d-flex">
                    <v-pagination v-model="page" :length="pages" @input="updatePage" :total-visible="5"></v-pagination>
                    <div class="ml-5" style="height: 2em; width: 5em;">
                        <v-select v-model="pageSize" :items="itemPageSize" label="Solo field" solo></v-select>
                    </div>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
import axios from "axios";
import SkeletonLoader from "../../components/SkeletonLoader.vue";
import JwtService from "../../../../../services/jwt.services";
export default {
    components: { SkeletonLoader },
    props: {
        typeMedia: {
            default: "",
        },
        typeSentiment: {
            default: "",
        },
        startDate: {
            default: "",
        },
        endDate: {
            default: "",
        },
        search: {
            default: ""
        }
    },
    data() {
        return {
            page: 1,
            pageSize: 10,
            itemPageSize: [10, 20, 50, 100],
            listCount: 0,
            listTotal: 0,
            opsel: [],
            limit: 40,
            historyList: [],
            data: [],
            loading: false,
            app_name: "",
            dialog: [],
            date_open: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            selected_cell_operator: 0,
            selected_data: {
                province_id: 0,
                city_id: 0,
                cell_operator_id: 0,
            },
            list_category: [
                {
                    id: 1,
                    name: "Service",
                },
                {
                    id: 2,
                    name: "Network",
                },
            ],
            category: {},
            subject: "",
            description: "",
            attachment: {},



        }
    },
    created() {
        this.updatePage(this.page);
    },
    computed: {
        pages: function () {
            if (this.pageSize == null || this.listCount == null) return 0;
            return Math.ceil(this.listCount / this.pageSize);
        },
        typeMediaWithComma: function () {
            return this.typeMedia.toString();
        },
        typeSentimentWithComma: function () {
            return this.typeSentiment.toString();
        },
        itemOperator() {
            return this.$store.getters.item_opsel;
        },
    },
    methods: {
        initialize() {
            this.getSocialListTotal()
        },
        getSocialList() {
            this.loading = true;
            axios.get(process.env.VUE_APP_API_LOD_URL + "api/get_mention_lists", {
                auth: {
                    username: process.env.VUE_APP_LOD_USERNAME,
                    password: process.env.VUE_APP_LOD_PASSWORD
                },
                params: {
                    source: this.typeMediaWithComma,
                    tone: this.typeSentimentWithComma,
                    search: this.search,
                    start_date: this.startDate,
                    end_date: this.endDate,
                    limit: this.limit,
                    category: "pos"
                }
            }).then((response) => {
                this.loading = false;
                this.data = response.data.data;
                this.listTotal = response.data.total;
                this.limit = response.data.total;
                this.listCount = response.data.data.length;
                if (this.listCount < this.pageSize) {
                    this.historyList = response.data.data;
                } else {
                    this.historyList = response.data.data.slice(0, this.pageSize);
                }
            })
        },
        initPage: function () {
            this.listCount = this.data.length;
            if (this.listCount < this.pageSize) {
                this.historyList = this.data;
            } else {
                this.historyList = this.data.slice(0, this.pageSize);
            }
        },
        updatePage: function (pageIndex) {
            let start = (pageIndex - 1) * this.pageSize;
            let end = pageIndex * this.pageSize;
            this.historyList = this.data.slice(start, end);
            this.page = pageIndex;
            document.querySelector('#list-mention')?.scrollTo(0, 0);
        },
        getSocialListTotal: function () {
            this.loading = true;
            axios.get(process.env.VUE_APP_API_LOD_URL + "api/get_mention_lists", {
                auth: {
                    username: process.env.VUE_APP_LOD_USERNAME,
                    password: process.env.VUE_APP_LOD_PASSWORD
                },
                params: {
                    source: this.typeMediaWithComma,
                }
            }).then((response) => {
                this.loading = false;
                this.limit = response.data.total;
                this.getSocialList();
            })
        },

        setupCreateTicket(item) {
            // this.dialog = true;
            this.selected_ticket = [];
            this.selected_ticket.push(item);
            this.app_name = item.source_type;
            this.selected_cell_operator = item.cell_operator_id;
        },
        submitFilter: function () {
            this.getSocialListTotal();
        },
        getOperator() {
            // this.loading = true;
            const data = {
                limit: -1,
                page: 1,
                status: "true",
                sort: "asc",
                type: 1
            };
            fetch(process.env.VUE_APP_API_URL + `api/v1/id/cell-operator`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${JwtService.getToken()}`
                }
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error == false) {
                        this.opsel = result.data.data;
                        // this.loading = false;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        submitCreateTicket() {
            console.log(this.attachment)
        }
    },
    watch: {
        // typeMedia: function () {
        //     this.getSocialListTotal()
        // },
        // typeSentiment: function () {
        //     this.getSocialListTotal()
        // },
        // search: function () {
        //     this.getSocialListTotal()
        // },
        // startDate: function () {
        //     this.getSocialListTotal()
        // },
        pageSize: function () {
            this.getSocialListTotal()
        }
    },
    mounted() {
        this.initialize()
        this.getOperator()
    }
}
</script>
<style>
.button-hover-blue:hover {
    color: rgb(133, 133, 218);
    cursor: pointer;
}

.list-mention::-webkit-scrollbar {
    background-color: rgb(233, 228, 228);
    width: 5px;
}

.list-mention::-webkit-scrollbar-thumb {
    background-color: #706e6e;
}
</style>