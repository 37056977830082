import { render, staticRenderFns } from "./Pos.vue?vue&type=template&id=f24c8f32&scoped=true"
import script from "./Pos.js?vue&type=script&lang=js&external"
export * from "./Pos.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f24c8f32",
  null
  
)

export default component.exports