<template>
    <div id="chart">
        <v-row>
            <v-col lg="6">
                <div class="d-flex">
                    <strong>{{ $t("ui.field.complaint") }}</strong>
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-1" v-bind="attrs" v-on="on"
                                style="color: rgb(199, 197, 197)00, 90, 90); cursor: pointer;">mdi-help-circle-outline</v-icon>
                        </template>
                        <span>
                            <p style="max-width: 15em;">{{ $t("tooltip.mention") }}</p>
                        </span>
                    </v-tooltip>
                </div>
                <apexchart ref="realtimeChart" type="line" height="350" :options="chartOptionsMention"
                    :series="seriesMention">
                </apexchart>
            </v-col>
            <v-col lg="6">
                <div class="d-flex">
                    <strong>{{ $t("ui.field.reach") }}</strong>
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-1" v-bind="attrs" v-on="on"
                                style="color: rgb(199, 197, 197)00, 90, 90); cursor: pointer;">mdi-help-circle-outline</v-icon>
                        </template>
                        <span>
                            <p style="max-width: 15em;">{{ $t("tooltip.reach") }}</p>
                        </span>
                    </v-tooltip>
                </div>
                <apexchart ref="realtimeChart" type="line" height="350" :options="chartOptionsReach" :series="seriesReach">
                </apexchart>
            </v-col>
        </v-row>
    </div>
</template>
  
<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import moment from "moment";

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        date_1: {
            default: '',
        },
        date_2: {
            default: '',
        }
    },
    data: function () {
        return {
            seriesMention: [],
            fullDataMention: [],
            seriesReach: [],
            fullDataReach: [],
            chartOptionsMention: {
                chart: {
                    height: 350,
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                    toolbar: {
                        show: false
                    }
                },
                colors: ["#0866FF", "#000000", "#DD2A7B", "#DB4F43"],
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: 3
                },
                title: {
                    text: 'Social Media Analytics',
                    align: 'left',
                    style: {
                        fontSize: '11px',
                        fontWeight: 'thin',
                        fontFamily: undefined,
                        color: 'white',
                        visibility: "hidden"
                    },
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 1
                },
                xaxis: {
                    title: {
                        text: 'Date',
                    }
                },
                yaxis: {
                    title: {
                        text: 'Aduan Masyarakat'
                    },
                    labels: {
                        formatter: function (val) {
                            var parts = val.toString().split(".");
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            return parts.join(".");
                        }
                    }
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: true,
                    offsetY: -25,
                    offsetX: -5
                }
            },
            chartOptionsReach: {
                chart: {
                    height: 350,
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                    toolbar: {
                        show: false
                    }
                },
                colors: ["#0866FF", "#000000", "#DD2A7B", "#DB4F43"],
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: 3
                },
                title: {
                    text: 'Social Media Analytics',
                    align: 'left',
                    style: {
                        fontSize: '11px',
                        fontWeight: 'thin',
                        fontFamily: undefined,
                        color: 'white',
                        visibility: "hidden"
                    },
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 1
                },
                xaxis: {
                    title: {
                        text: 'Date',
                    }
                },
                yaxis: {
                    title: {
                        text: 'Jangkauan Masyarakat'
                    },
                    labels: {
                        formatter: function (val) {
                            var parts = val.toString().split(".");
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            return parts.join(".");
                        }
                    }
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: true,
                    offsetY: -25,
                    offsetX: -5
                }
            },
            date_picker_social_chart_1: false,
            date_picker_social_chart_2: false,
        }
    },
    computed: {
        initialData: function () {
            const data = {
                facebook: [
                    {
                        date: "2023-10-28",
                        source_type: 1
                    },
                    {
                        date: "2023-10-28",
                        source_type: 80
                    },
                    {
                        date: "2023-10-28",
                        source_type: 140
                    },

                ],
                twitter: [
                    {
                        date: "2023-10-28",
                        source_type: 130
                    },
                    {
                        date: "2023-10-28",
                        source_type: 140
                    },
                    {
                        date: "2023-10-28",
                        source_type: 120
                    },
                ],
                instagram: [
                    {
                        date: "2023-10-28",
                        source_type: 23
                    },
                    {
                        date: "2023-10-28",
                        source_type: 190
                    },
                    {
                        date: "2023-10-28",
                        source_type: 290
                    },
                ],
                other: [
                    {
                        date: "2023-10-28",
                        source_type: 1
                    },
                    {
                        date: "2023-10-28",
                        source_type: 120
                    },
                    {
                        date: "2023-10-28",
                        source_type: 120
                    },
                ]
            }
            let newSeries = [
                {
                    data: data.facebook.map(item => { return item.source_type }),
                    name: 'Facebook',
                },
                {
                    data: data.twitter.map(item => { return item.source_type }),
                    name: 'Twitter',
                },
                {
                    data: data.instagram.map(item => { return item.source_type }),
                    name: 'Instagram',
                },
                {
                    data: data.other.map(item => { return item.source_type }),
                    name: 'Others',
                },
            ]
            return newSeries
        },
        socialStartDate: function () {
            if (this.date_1 == "") {
                return "All Time"
            } else {
                return `${moment(this.date_1).format("YYYY-MM-DD")}`;
            }
        },
        socialEndDate: function () {
            if (this.date_2 == "") {
                return "All Time"
            } else {
                return `${moment(this.date_2).format("YYYY-MM-DD")}`;
            }
        },
    },
    methods: {
        initialize() {
            this.getSocialChartMention();
            this.getSocialChartReach();
        },
        getSocialChartMention() {
            axios.get(process.env.VUE_APP_API_LOD_URL + "api/get_mention_chart", {
                auth: {
                    username: process.env.VUE_APP_LOD_USERNAME,
                    password: process.env.VUE_APP_LOD_PASSWORD
                },
                params: {
                    start_date: this.date_1,
                    end_date: this.date_2,
                    category: "pos"
                }
            }).then((response) => {

                let newSeries = [
                    {
                        data: response.data.facebook.map(item => { return item.sourceType }),
                        name: 'Facebook',
                    },
                    {
                        data: response.data.twitter.map(item => { return item.sourceType }),
                        name: 'Twitter',
                    },
                    {
                        data: response.data.instagram.map(item => { return item.sourceType }),
                        name: 'Instagram',
                    },
                    {
                        data: response.data.other.map(item => { return item.sourceType }),
                        name: 'Others',
                    },
                ]
                this.seriesMention = newSeries
                this.fullData = {
                    facebook: response.data.facebook,
                    twitter: response.data.twitter,
                    instagram: response.data.instagram,
                    other: response.data.other
                }
                this.chartOptionsMention = {
                    ...this.chartOptionsMention,
                    xaxis: {
                        labels: {
                            rotate: -45,
                            rotateAlways: true,
                        },
                        categories: response.data.twitter.map(item => { return moment(item.date).format("MM-DD") }),
                        title: Date
                    },
                }
            })
        },
        getSocialChartReach() {
            axios.get(process.env.VUE_APP_API_LOD_URL + "api/get_reach_chart", {
                auth: {
                    username: process.env.VUE_APP_LOD_USERNAME,
                    password: process.env.VUE_APP_LOD_PASSWORD
                },
                params: {
                    start_date: this.date_1,
                    end_date: this.date_2,
                    category: "pos"
                }
            }).then((response) => {
                let newSeries = [
                    {
                        data: response.data.facebook.map(item => { return item.sourceType }),
                        name: 'Facebook',
                    },
                    {
                        data: response.data.twitter.map(item => { return item.sourceType }),
                        name: 'Twitter',
                    },
                    {
                        data: response.data.instagram.map(item => { return item.sourceType }),
                        name: 'Instagram',
                    },
                    {
                        data: response.data.other.map(item => { return item.sourceType }),
                        name: 'Others',
                    },
                ]
                this.seriesReach = newSeries
                this.fullDataReach = {
                    facebook: response.data.facebook,
                    twitter: response.data.twitter,
                    instagram: response.data.instagram,
                    other: response.data.other
                }
                this.chartOptionsReach = {
                    ...this.chartOptionsReach,
                    xaxis: {
                        labels: {
                            rotate: -45,
                            rotateAlways: true,
                        },
                        categories: response.data.twitter.map(item => { return moment(item.date).format("MM-DD") }),
                        title: Date
                    },
                }
            })
        },
        getRandomArbitrary() {
            return Math.floor(Math.random() * 35);
        },
        inputDate() {
            this.start_date = this.date_1;
            this.end_date = this.date_2;
        },
        submitSocial() {
            this.getSocialChartMention()
            this.getSocialChartReach()
        },
        resetSocial() {
            this.date_1 = ""
            this.date_2 = ""
            this.getSocialChartMention()
            this.getSocialChartReach()
        },
    },
    mounted() {
        this.initialize();
    }
};
</script>
  
<style></style>