<template>
    <div id="chart">
        <div class="font-weight-bold mt-5 ml-2" style="font-size: 19px">
            {{ $t("ui.field.overall") }}
        </div>
        <v-card class="pa-5 montel-card">
            <div class="mb-4">
                <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" style="background-color: white;">
                            Source
                            <v-icon class="me-2">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title>
                                <div class="d-flex align-center mb-2 ">
                                    <input class="mr-2" type="radio" hide-details v-model="sourceValue" value="twitter" />
                                    <font-awesome-icon class="me-2" style="color: black;" icon="fa-brands fa-x-twitter" />
                                    Twitter
                                </div>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>
                                <div class="d-flex align-center mb-2 ">
                                    <input class="mr-2" type="radio" hide-details v-model="sourceValue" value="facebook" />
                                    <v-icon class="me-2" style="color: blueviolet;">mdi-facebook</v-icon>
                                    Facebook
                                </div>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>
                                <div class="d-flex align-center mb-2">
                                    <input class="mr-2" type="radio" hide-details v-model="sourceValue" value="instagram" />
                                    <v-icon class="me-2" style="color: red;">mdi-instagram</v-icon>
                                    Instagram
                                </div>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>
                                <div class="d-flex align-center mb-2">
                                    <input class="mr-2" type="radio" hide-details v-model="sourceValue" value="others" />
                                    <v-icon class="me-2" style="color: black;">mdi-star</v-icon>
                                    Other
                                </div>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <v-row>
                <!-- <v-col lg="4">
                    <SocialMediaPieChart />
                </v-col> -->
                <v-col lg="6">
                    <div class="d-flex">
                        <strong>{{ $t("ui.field.complaint") }}</strong>
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="ml-1" v-bind="attrs" v-on="on"
                                    style="color: rgb(199, 197, 197)00, 90, 90); cursor: pointer;">mdi-help-circle-outline</v-icon>
                            </template>
                            <span style="max-width: 2em;">
                                <p style="max-width: 15em;">{{ $t("tooltip.mention_sentiment") }}</p>
                            </span>
                        </v-tooltip>
                    </div>
                    <apexchart ref="realtimeChart" type="bar" height="350" :options="chartOptionsMention"
                        :series="seriesMention">
                    </apexchart>
                </v-col>
                <v-col lg="6">
                    <div class="d-flex">
                        <strong>{{ $t("ui.field.reach") }}</strong>
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="ml-1" v-bind="attrs" v-on="on"
                                    style="color: rgb(199, 197, 197)00, 90, 90); cursor: pointer;">mdi-help-circle-outline</v-icon>
                            </template>
                            <span>
                                <p style="max-width: 15em;">{{ $t("tooltip.reach_sentiment") }}</p>
                            </span>
                        </v-tooltip>
                    </div>
                    <apexchart ref="realtimeChart" type="bar" height="350" :options="chartOptionsReach"
                        :series="seriesReach">
                    </apexchart>
                </v-col>
            </v-row>
        </v-card>

        <div class="font-weight-bold mt-5 ml-2" style="font-size: 19px">
            {{ $t("ui.field.trends") }}
        </div>
        <v-card class="pa-5 montel-card">
            <v-row>
                <v-col lg="12">
                    <SocialMediaLineChart ref="socialLineChart" :date_1="date_1" :date_2="date_2" />
                </v-col>
            </v-row>
            <v-row>
                <v-col lg="12">
                    <SocialMediaChart ref="socialChart" :date_1="date_1" :date_2="date_2" />
                </v-col>
            </v-row>
        </v-card>
        <v-overlay v-model="loading">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import SocialMediaChart from "./SocialMediaChart.vue";
import SocialMediaLineChart from "./SocialMediaLineChart.vue";
import axios from "axios";
import SocialMediaPieChart from "./SocialMediaPieChart.vue";
// import moment from "moment";
export default {
    components: {
        apexchart: VueApexCharts, SocialMediaChart,
        SocialMediaLineChart,
        SocialMediaPieChart
    },
    props: {
        date_1: {
            default: '',
        },
        date_2: {
            default: '',
        }
    },
    data: function () {
        return {
            // sourceItem: [],
            sourceValue: 'twitter',
            chartOptionsMention: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    stackType: '100%',
                    toolbar: {
                        show: false,
                    }
                },
                colors: ['#5088ED', '#D9D9D9', '#DB4F43'],
                plotOptions: {
                    bar: {
                        horizontal: true
                    },
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                title: {
                    text: ''
                },
                xaxis: {
                    categories: [],
                    labels: {
                        formatter: function (val) {
                            return val + "%"
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: undefined
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + "%"
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                }
            },
            seriesMention: [],
            chartOptionsReach: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    stackType: '100%',
                    toolbar: {
                        show: false,
                    }
                },
                colors: ['#5088ED', '#D9D9D9', '#DB4F43'],
                plotOptions: {
                    bar: {
                        horizontal: true
                    },
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                title: {
                    text: ''
                },
                xaxis: {
                    categories: [],
                    labels: {
                        formatter: function (val) {
                            return val + "%"
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: undefined
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + "%"
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                }
            },
            seriesReach: [],
            loading: false
        }
    },
    methods: {
        initailize() {
            this.getSocialChartMention();
            this.getSocialChartReach();
        },
        submitLineChart: function () {
            console.log(this.date_1)
            this.$refs.socialChart.submitSocial();
            this.$refs.socialLineChart.submitSocial();
            this.getSocialChartMention();
            this.getSocialChartReach();
        },
        getSocialChartMention() {
            this.loading = true;
            axios.get(process.env.VUE_APP_API_LOD_URL + "api/get_mention_sentiment_source_chart", {
                auth: {
                    username: process.env.VUE_APP_LOD_USERNAME,
                    password: process.env.VUE_APP_LOD_PASSWORD
                },
                params: {
                    start_date: this.date_1,
                    end_date: this.date_2,
                    source: this.sourceValue,
                    category: "fo"
                }
            }).then((response) => {
                this.loading = false;
                let newSeries = [
                    {
                        data: response.data.data.map(item => { return item.positive }),
                        name: 'Positive',
                    },
                    {
                        data: response.data.data.map(item => { return item.neutral }),
                        name: 'Neutral',
                    },
                    {
                        data: response.data.data.map(item => { return item.negative }),
                        name: 'Negative',
                    }
                ]
                this.seriesMention = newSeries
                this.chartOptionsMention = {
                    ...this.chartOptionsMention,
                    xaxis: {
                        labels: {
                            rotate: -45,
                            rotateAlways: true,
                        },
                        categories: response.data.data.map(item => { return item.brand }),
                        title: Date
                    },
                }
            }).catch(err => { console.log(err); this.loading = false })
        },
        getSocialChartReach() {
            axios.get(process.env.VUE_APP_API_LOD_URL + "api/get_mention_sentiment_reach_source_chart", {
                auth: {
                    username: process.env.VUE_APP_LOD_USERNAME,
                    password: process.env.VUE_APP_LOD_PASSWORD
                },
                params: {
                    start_date: this.date_1,
                    end_date: this.date_2,
                    source: this.sourceValue,
                    category: "fo"
                }
            }).then((response) => {
                let newSeries = [
                    {
                        data: response.data.data.map(item => { return item.positive }),
                        name: 'Positive',
                    },
                    {
                        data: response.data.data.map(item => { return item.neutral }),
                        name: 'Neutral',
                    },
                    {
                        data: response.data.data.map(item => { return item.negative }),
                        name: 'Negative',
                    }
                ]
                this.seriesReach = newSeries
                this.chartOptionsReach = {
                    ...this.chartOptionsMention,
                    xaxis: {
                        labels: {
                            rotate: -45,
                            rotateAlways: true,
                        },
                        categories: response.data.data.map(item => { return item.brand }),
                        title: Date
                    },
                }
            })
        },
    },
    watch: {
        sourceValue: function () {
            this.initailize();
        }
    },
    mounted() {
        this.initailize();
    }
}
</script>