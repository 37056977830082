<template>
  <div id="chart">
    <v-row>
      <v-col lg="6">
        <div class="d-flex">
          <strong>{{ $t("ui.field.complaint") }}</strong>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-1" v-bind="attrs" v-on="on"
                style="color: rgb(199, 197, 197)00, 90, 90); cursor: pointer;">mdi-help-circle-outline</v-icon>
            </template>
            <span>
              <p style="max-width: 15em;">{{ $t("tooltip.mention_sentiment") }}</p>
            </span>
          </v-tooltip>
        </div>
        <apexchart ref="realtimeChart" type="bar" height="350" :options="chartOptionsMention" :series="seriesMention">
        </apexchart>
      </v-col>
      <v-col lg="6">
        <div class="d-flex">
          <strong>{{ $t("ui.field.reach") }}</strong>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-1" v-bind="attrs" v-on="on"
                style="color: rgb(199, 197, 197)00, 90, 90); cursor: pointer;">mdi-help-circle-outline</v-icon>
            </template>
            <span>
              <p style="max-width: 15em;">{{ $t("tooltip.reach_sentiment") }}</p>
            </span>
          </v-tooltip>
        </div>
        <apexchart ref="realtimeChart" type="bar" height="350" :options="chartOptionsReach" :series="seriesReach">
        </apexchart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import moment from "moment";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    date_1: {
      default: '',
    },
    date_2: {
      default: '',
    }
  },
  data: function () {
    return {
      seriesMention: [],
      fullDataMention: [],
      seriesReach: [],
      fullDataReach: [],
      chartOptionsMention: {
        chart: {
          type: 'bar',
          height: 500,
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false
          }
        },
        colors: ['#5088ED', '#D9D9D9', '#DB4F43'],
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: ''
        },
        xaxis: {
          // categories: ["3", "Smartfren", "XL", "Indosat"],
          // labels: {
          //   formatter: function (val) {
          //     return val + "%"
          //   }
          // }
        },
        yaxis: {
          title: {
            text: undefined
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "%"
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
      chartOptionsReach: {
        chart: {
          type: 'bar',
          height: 500,
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false
          }
        },
        colors: ['#5088ED', '#D9D9D9', '#DB4F43'],
        // plotOptions: {
        //   bar: {
        //     horizontal: true
        //   },
        // },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: ''
        },
        xaxis: {
          // categories: ["3", "Smartfren", "XL", "Indosat"],
          // labels: {
          //   formatter: function (val) {
          //     return val + "%"
          //   }
          // }
        },
        yaxis: {
          title: {
            text: undefined
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "%"
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
      date_picker_social_chart_1: false,
      date_picker_social_chart_2: false,
    }
  },
  computed: {
    socialStartDate: function () {
      if (this.date_1 == "") {
        return "All Time"
      } else {
        return `${moment(this.date_1).format("YYYY-MM-DD")}`;
      }
    },
    socialEndDate: function () {
      if (this.date_2 == "") {
        return "All Time"
      } else {
        return `${moment(this.date_2).format("YYYY-MM-DD")}`;
      }
    },
  },
  methods: {
    initialize() {
      this.getSocialChartMention();
      this.getSocialChartReach();
    },
    getSocialChartMention() {
      axios.get(process.env.VUE_APP_API_LOD_URL + "api/get_mention_sentiment_general_chart", {
        auth: {
          username: process.env.VUE_APP_LOD_USERNAME,
          password: process.env.VUE_APP_LOD_PASSWORD
        },
        params: {
          start_date: this.date_1,
          end_date: this.date_2,
          category: "fo"
        }
      }).then((response) => {
        let newSeries = [
          {
            data: response.data.data.map(item => { return item.positive }),
            name: 'Positive',
          },
          {
            data: response.data.data.map(item => { return item.neutral }),
            name: 'Neutral',
          },
          {
            data: response.data.data.map(item => { return item.negative }),
            name: 'Negative',
          }
        ]
        this.seriesMention = newSeries
        this.chartOptionsMention = {
          ...this.chartOptionsMention,
          xaxis: {
            labels: {
              rotate: -45,
              rotateAlways: true,
            },
            categories: response.data.data.map(item => { return moment(item.date).format("MM-DD") }),
            title: Date
          },
        }
      })
    },
    getSocialChartReach() {
      axios.get(process.env.VUE_APP_API_LOD_URL + "api/get_mention_sentiment_reach_general_chart", {
        auth: {
          username: process.env.VUE_APP_LOD_USERNAME,
          password: process.env.VUE_APP_LOD_PASSWORD
        },
        params: {
          start_date: this.date_1,
          end_date: this.date_2,
          category: "fo"
        }
      }).then((response) => {
        let newSeries = [
          {
            data: response.data.data.map(item => { return item.positive }),
            name: 'Positive',
          },
          {
            data: response.data.data.map(item => { return item.neutral }),
            name: 'Neutral',
          },
          {
            data: response.data.data.map(item => { return item.negative }),
            name: 'Negative',
          }
        ]
        this.seriesReach = newSeries
        this.chartOptionsReach = {
          ...this.chartOptionsMention,
          xaxis: {
            labels: {
              rotate: -45,
              rotateAlways: true,
            },
            categories: response.data.data.map(item => { return moment(item.date).format("MM-DD") }),
            title: Date
          },
        }
      })
    },
    inputDate() {
      this.start_date = this.date_1;
      this.end_date = this.date_2;
    },
    submitSocial() {
      this.getSocialChartMention()
      this.getSocialChartReach()
    },
    resetSocial() {
      this.date_1 = ""
      this.date_2 = ""
      this.getSocialChartMention()
      this.getSocialChartReach()
    },
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style></style>