import { render, staticRenderFns } from "./TicketingRadio.vue?vue&type=template&id=b9136648&scoped=true"
import script from "./TicketingRadio.js?vue&type=script&lang=js&external"
export * from "./TicketingRadio.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9136648",
  null
  
)

export default component.exports