<template>
  <div class="radio">
    <div class="font-weight-bold mt-5" style="font-size: 24px">
      infografis {{ $t("general.menu.data_radio_broadcast") }}
    </div>
    <v-row class="mt-5">
      <v-col cols="auto" v-for="(item, index) in subTab" :key="index">
        <v-btn
          width="100%"
          class="text-capitalize text-grey"
          height="50"
          rounded
          elevation="0"
          @click="changeComponent(item.navigationTo)"
          :class="[
            `text-capitalize`,
            activeItem == item.navigationTo ? `active-btn` : ``,
          ]"
        >
          {{ $t(item.name) }}
        </v-btn>
      </v-col>
    </v-row>

    <!-- Component Data Radio Broadcasting Choice -->
    <component :is="radio" />
  </div>
</template>

<script>
import RadioBroadcastingTower from "./components/RadioBroadcastingTower/RadioBroadcastingTower.vue";
import CoverageTransmitterRadio from "./components/CoverageTransmitterRadio/CoverageTransmitterRadio.vue";

export default {
  components: {
    RadioBroadcastingTower,
    CoverageTransmitterRadio,
  },
  data: () => ({
    radio: RadioBroadcastingTower,
    activeItem: "Radio Broadcasting Tower",
    subTab: [
      {
        name: "ui.button_radio_broadcasting_tower",
        navigationTo: "Radio Broadcasting Tower",
      },
      {
        name: "ui.button_coverage_transmitter_radio",
        navigationTo: "Coverage Transmitter Radio",
      },
    ],
  }),
  methods: {
    changeComponent(value) {
      this.activeItem = value;
      if (value == "Radio Broadcasting Tower") {
        this.radio = RadioBroadcastingTower;
      } else {
        this.radio = CoverageTransmitterRadio;
      }
    },
  },
  
};
</script>

<style lang="scss">
.radio {
  height: 100%;
}
.active-btn {
  background-color: #ffba3b !important;
  color: white !important;
}
.text-grey {
  color: #867e7e;
}
.radio-chart {
  .apexcharts-menu-item.exportCSV {
    display: none !important;
  }
}
</style>
