var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pmt-flex is-row wrap row-gap-2 pl-5 pr-5 pt-2 pb-8"},[_c("v-btn",{tag:"button",class:[
      `text-capitalize text-capitalize pl-6 pr-6`,
      _vm.isPage == 'Operator Cellular' ? `white--text` : ``,
    ],attrs:{"active-class":"active-link","rounded":"","outlined":_vm.isPage == 'Operator Cellular' ? false : true,"to":{ path: _vm.link.cellular }}},[_vm._v(" "+_vm._s(_vm.$t("general.menu.operator_cellular"))+" ")]),_c("v-btn",{tag:"button",class:[
      `text-capitalize text-capitalize pl-6 pr-6 ml-6`,
      _vm.isPage == 'Fixed Broadband' ? `white--text` : ``,
    ],attrs:{"active-class":"active-link","rounded":"","outlined":_vm.isPage == 'Fixed Broadband' ? false : true,"to":{ path: _vm.link.fo }}},[_vm._v(" "+_vm._s(_vm.$t("general.menu.fixed_broadband"))+" ")]),(_vm.link.pos)?_c("v-btn",{tag:"button",class:[
      `text-capitalize text-capitalize pl-6 pr-6 ml-6`,
      _vm.isPage == 'Data Pos' ? `white--text` : ``,
    ],attrs:{"active-class":"active-link","rounded":"","outlined":_vm.isPage == 'Data Pos' ? false : true,"to":{ path: _vm.link.pos }}},[_vm._v(" "+_vm._s(_vm.$t("general.menu.data_post"))+" ")]):_vm._e(),(_vm.link.tv)?_c("v-btn",{tag:"button",class:[
      `text-capitalize text-capitalize pl-6 pr-6 ml-6`,
      _vm.isPage == 'Data Television Broadcasting' ? `white--text` : ``,
    ],attrs:{"active-class":"active-link","rounded":"","outlined":_vm.isPage == 'Data Television Broadcasting' ? false : true,"to":{ path: _vm.link.tv }}},[_vm._v(" "+_vm._s(_vm.$t("general.menu.data_television_broadcast"))+" ")]):_vm._e(),(_vm.link.radio)?_c("v-btn",{tag:"button",class:[
      `text-capitalize text-capitalize pl-6 pr-6 ml-6`,
      _vm.isPage == 'Data Radio Broadcasting' ? `white--text` : ``,
    ],attrs:{"active-class":"active-link","rounded":"","outlined":_vm.isPage == 'Data Radio Broadcasting' ? false : true,"to":{ path: _vm.link.radio }}},[_vm._v(" "+_vm._s(_vm.$t("general.menu.data_radio_broadcast"))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }