import { render, staticRenderFns } from "./BadSpot.vue?vue&type=template&id=ad65b7ea&scoped=true"
import script from "./BadSpot.js?vue&type=script&lang=js&external"
export * from "./BadSpot.js?vue&type=script&lang=js&external"
import style0 from "./BadSpot.vue?vue&type=style&index=0&id=ad65b7ea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad65b7ea",
  null
  
)

export default component.exports