import NavbarBigScreen from "../../../../layouts/NavbarFull/NavbarBigScreen.vue";
import NavigationCellAndFo from "../../../../components/navigation/CellularAndFo/NavigationCellAndFo.vue";
import VueApexCharts from "vue-apexcharts";
import {
    GET_START_DATE,
    GET_END_DATE,
  } from "../../../../store/modules/qoe.module";
export default {
    components: { 
        NavbarBigScreen, 
        NavigationCellAndFo,
        apexchart: VueApexCharts,
    },
    data:()=>({
        loadingData:false,
        dateValue:{},
        date_picker_1: false,
        date_picker_2: false,
        date_picker_3: false,
        date_picker_4: false,
        chartOptions: {
            chart: {
              type: "bar",
              height: 350,
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "55%",
                borderRadius: 3,
              },
            },
            colors: ["#CB4F4F", "#EA8426", "#CB1F4F", "#CB411F", "#F4F", "#F11"],
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },
            xaxis: {
              categories: [],
            },
            fill: {
              opacity: 1,
            },
            title: {
              text: "",
              align: "center",
              margin: 10,
              offsetX: 0,
              offsetY: 0,
              floating: false,
              style: {
                fontSize: "18px",
                fontWeight: "bold",
                fontFamily: undefined,
                color: "#263238",
              },
            },
          },
          date: {
            startDate: new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .substr(0, 10),
            endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10),
            default: true,
            defaultTable: true,
            defaultText: "All Time",
          },
    }),
    computed: {
        ticketCards() {
            // return this.$store.getters.ticketSummaryCard;
            // ambil dari getter store
            return {
              total_ticket: 0,
              open_ticket: 0,
              progress_ticket: 0,
              close_ticket: 0,
             
            };
          },
          organizerRadioBroadcastingChartOptions: function () {
            return {
              ...this.chartOptions,
              xaxis: {
                categories: [],
                // categories: ['Bali-NTT', 'Jawa', 'Kalimantan', 'Maluku-Papua', 'Sulawesi', 'Sumatera']
              },
              title: {
                ...this.chartOptions.title,
                text: `Penyiaran Radio`,
              },
            };
          },
    },
    methods: {
        initialize(){
    
        },
        inputDate(val) {
          if (val == "table") {
            this.date.defaultTable = false;
          } else {
            this.date.default = false;
          }
        },
        submitFilterDate() {
          if (this.date.default == true) {
            this.$swal({
              title: this.$t("ui.message.date_range"),
              confirmButtonColor: "#134889",
              icon: "info",
              showConfirmButton: true,
              timer: 3000,
            });
          } else {
            this.$store.dispatch(GET_START_DATE, this.date.startDate);
            this.$store.dispatch(GET_END_DATE, this.date.endDate);
            this.data = [];
            this.ticket_summary = [];
            this.getSummaryByOperators();
            this.initialize();
          }
        },
        resetFilterDate() {
            this.date.default = true;
      
            this.$store.dispatch(GET_START_DATE, "");
            this.$store.dispatch(GET_END_DATE, "");
            this.data = [];
            this.ticket_summary = [];
            this.getSummaryByOperators();
            this.initialize();
          },
      },
}