import { mapGetters, mapActions } from "vuex";
import VueApexCharts from "vue-apexcharts";
import {
  GET_PROVINCE,
  GET_CITY,
  GET_DISTRICT,
  GET_SUB_DISTRICT,
} from "../../../../../../store/modules/location.module";
import {
  GET_POS_COVERAGE,
  GET_ORGANIZERS_POS_COVERAGE,
  GET_POS_COVERAGE_RADIUS
} from "../../../../../../store/modules/pos.module";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    selectedProvince: 0,
    selectedCity: 0,
    selectedDistrict: 0,
    selectedScope: "",
    searchProvince: "",
    searchCity: "",
    searchDistrict: "",
    searchScope: "",
    pagination: {
      page: 1,
      limit: 10,
      totalItems: 0,
      rowsPerPageItems: [10, 20, 30, 40, 500],
    },
    organizerPoscategories: [],
    organizerPosSeries: [],
    organizerPosCoverageChartTitle: "",
    loadingTable: false,
    loadingChart: false,
    chartOptions: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 2,
        },
      },
      colors: ["#CB4F4F", "#EA8426", "#CB1F4F", "#CB411F", "#F4F", "#F11"],
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      fill: {
        opacity: 1,
      },
      title: {
        text: "",
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#263238",
        },
      },
    },
    headers: [
      {
        text: "Cakupan Wilayah",
        value: "name",
      },
      {
        text: "Kecamatan",
        value: "nkec",
      },
      {
        text: "Kabupaten/Kota",
        value: "nkab",
      },
      {
        text: "Provinsi",
        value: "nprov",
      },
    ],
  }),
  computed: {
    ...mapGetters([
      "item_province",
      "item_city",
      "item_district",
      "posCoverage",
      "paginationPosCoverage",
      "dateUpdateOrganizerPosCoverage",
      "organizerPosCoverage",
      "posCoverageRadius"
    ]),
    organizerPosCoverageChartOptions: function () {
      return {
        ...this.chartOptions,
        xaxis: {
          categories: this.organizerPosCoverage.categories || [],
        },
        title: {
          ...this.chartOptions.title,
          text: `Total Coverage Layanan Pos ${this.organizerPosCoverageChartTitle}`,
        },
      };
    },
  },
  methods: {
    ...mapActions([
      GET_PROVINCE,
      GET_CITY,
      GET_DISTRICT,
      GET_SUB_DISTRICT,
      GET_POS_COVERAGE,
      GET_ORGANIZERS_POS_COVERAGE,
      GET_POS_COVERAGE_RADIUS,
    ]),
    async initialize() {
      this[GET_PROVINCE]();
      await this[GET_POS_COVERAGE_RADIUS]()
      this.selectedScope = this.posCoverageRadius[0]?.value || ''
      await this.handleGetposCoverageData();
      this.handleGetOrganizersPosCoverage();
    },
    handleGetposCoverage() {},
    async handleGetposCoverageData() {
      this.loadingTable = true
      const { page, limit } = this.pagination;
      const params = {
        page,
        limit,
        ...(this.selectedProvince
          ? {
              province: this.item_province.find(
                (item) => item.id == this.selectedProvince
              ).id,
            }
          : {}),
        ...(this.selectedCity
          ? {
              city: this.item_city.find((item) => item.id == this.selectedCity)
                .code,
            }
          : {}),
        ...(this.selectedDistrict
          ? {
              district: this.item_district.find(
                (item) => item.id == this.selectedDistrict
              ).code,
            }
          : {}),
        ...(this.selectedScope ? {radius: this.selectedScope}: {})
      };
      await this[GET_POS_COVERAGE](params);
      this.loadingTable = false
    },
    async handleGetOrganizersPosCoverage() {
      this.loadingChart = true
      const params = {
        ...(this.selectedProvince
          ? {
              province: this.item_province.find(
                (item) => item.id == this.selectedProvince
              ).id,
            }
          : {}),
        ...(this.selectedCity
          ? {
              city: this.item_city.find((item) => item.id == this.selectedCity)
                .code,
            }
          : {}),
          ...(this.selectedScope ? {radius: this.selectedScope}: {})
      };
      await this[GET_ORGANIZERS_POS_COVERAGE](params);
      this.loadingChart = false
      
      if (this.selectedCity) {
        this.organizerPosCoverageChartTitle = `${
          this.item_city.find((item) => item.id == this.selectedCity).name
        }, ${(this.organizerPosCoverageChartTitle = this.item_province.find(
          (item) => item.id == this.selectedProvince
        ).name)}`;
      } else if (this.selectedProvince) {
        this.organizerPosCoverageChartTitle = this.item_province.find(
          (item) => item.id == this.selectedProvince
        ).name;
      } else {
        this.organizerPosCoverageChartTitle = "Nasional";
      }
    },
    selectCity() {
      if (this.selectedProvince == 0) {
        this[GET_CITY](this.selectedProvince);
      } else {
        this.selectedCity = 0;
        this.selectedDistrict = 0;
        this.selectedSubDistrict = 0;
        this[GET_CITY](this.selectedProvince);
      }
    },
    selectDistrict() {
      this[GET_DISTRICT](this.selectedCity);
    },
    changeLimitPagination(val) {
      this.pagination = {
        ...this.pagination,
        page: 1,
        limit: val,
      };
    },
    handleApply() {
      this.pagination = {
        ...this.pagination,
        page: 1,
      };
      this.handleGetOrganizersPosCoverage();
    },
    handleReset() {
      this.resetDataChart();
      this.resetDataTable();
    },
    resetDataChart() {
      this.selectedProvince = 0;
      this.selectedCity = 0;
      this.handleGetOrganizersPosCoverage();
    },
    resetDataTable() {
      this.selectedProvince = 0;
      this.selectedCity = 0;
      this.selectedDistrict = 0;
      this.pagination = {
        ...this.pagination,
        page: 1,
        limit: 10,
      };
    },
  },
  watch: {
    pagination: {
      handler() {
        this.handleGetposCoverageData();
      },
      deep: true,
    },
  },
  mounted() {
    this.initialize();
  },
};
