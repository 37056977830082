import { render, staticRenderFns } from "./TVBroadcastingData.vue?vue&type=template&id=53b45aa7&scoped=true"
import script from "./TVBroadcastingData.js?vue&type=script&lang=js&external"
export * from "./TVBroadcastingData.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53b45aa7",
  null
  
)

export default component.exports