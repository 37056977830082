import { mapGetters, mapActions } from "vuex";
import VueApexCharts from "vue-apexcharts";
import {
  GET_PROVINCE,
  GET_CITY,
  GET_DISTRICT,
} from "../../../../../../store/modules/location.module";
import {
  GET_TOWER_RADIO_BROADCASTING,
  GET_ORGANIZERS_RADIO_BROADCASTING,
  GET_TOWER_RADIO_SUB_SERVICE,
} from "../../../../../../store/modules/radio.module";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    selectedProvince: 0,
    selectedCity: 0,
    selectedDistrict: 0,
    selectedScope: "",
    searchProvince: "",
    searchCity: "",
    searchDistrict: "",
    subService: "",
    pagination: {
      page: 1,
      limit: 10,
      totalItems: 0,
      rowsPerPageItems: [10, 20, 30, 40, 500],
    },
    organizerRadioBroadcastingChartTitle: "",
    chartOptions: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 2,
        },
      },
      colors: ["#CB4F4F", "#EA8426", "#CB1F4F", "#CB411F", "#F4F", "#F11"],
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      fill: {
        opacity: 1,
      },
      title: {
        text: "",
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#263238",
        },
      },
    },
    headers: [
      {
        text: "Client Name",
        value: "clnt_name",
      },
      {
        text: "freq",
        value: "freq",
      },
      {
        text: "Sub Service",
        value: "subservice",
      },
      {
        text: "stn_addr",
        value: "stn_addr",
      },
      {
        text: "District",
        value: "district",
      },
      {
        text: "City",
        value: "city",
      },
      {
        text: "Province",
        value: "province",
      },

      {
        text: "Longitude",
        value: "long",
      },

      {
        text: "Latitude",
        value: "lat",
      },
    ],
    loadingTable: false,
    loadingChart: false,
  }),
  computed: {
    ...mapGetters([
      "item_province",
      "item_city",
      "item_district",
      "towerRadioBroadcasting",
      "paginationTowerRadioBroadcasting",
      "dateUpdateRadioBroadcasting",
      "organizerRadioBroadcasting",
      "towerRadioSubService"
    ]),
    organizerTowerRadioChartOptions: function () {
      return {
        ...this.chartOptions,
        xaxis: {
          categories: this.organizerRadioBroadcasting.categories || [],
          // categories: ['Bali-NTT', 'Jawa', 'Kalimantan', 'Maluku-Papua', 'Sulawesi', 'Sumatera']
        },
        title: {
          ...this.chartOptions.title,
          text: `Total Penyelenggara Penyiaran Radio ${this.organizerRadioBroadcastingChartTitle}`,
        },
      };
    },
  },
  methods: {
    ...mapActions([
      GET_PROVINCE,
      GET_CITY,
      GET_DISTRICT,
      GET_ORGANIZERS_RADIO_BROADCASTING,
      GET_TOWER_RADIO_BROADCASTING,
      GET_TOWER_RADIO_SUB_SERVICE
    ]),
    initialize() {
      this[GET_PROVINCE]()
      this[GET_TOWER_RADIO_SUB_SERVICE]()
      this.handleGetTowerRadioBroadcasting()
      this.handleGetOrganizersRadioBroadcasting()
    },
    selectCity() {
      if (this.selectedProvince == 0) {
        this[GET_CITY](this.selectedProvince);
      } else {
        this.selectedCity = 0;
        this.selectedDistrict = 0;
        this.selectedSubDistrict = 0;
        this[GET_CITY](this.selectedProvince);
      }
    },
    selectDistrict() {
      this[GET_DISTRICT](this.selectedCity);
    },
    async handleGetTowerRadioBroadcasting(){
      this.loadingTable = true
      const {page, limit} = this.pagination
      const params = {
        page,
        limit,
        ...(this.selectedProvince
          ? {
              province: this.item_province.find(
                (item) => item.id == this.selectedProvince
              ).name,
            }
          : {}),
        ...(this.selectedCity
          ? {
              city: this.item_city.find((item) => item.id == this.selectedCity)
                .name.replace('KAB. ', '').replace('KOTA ', ''),
            }
          : {}),
        ...(this.selectedDistrict
          ? {
              district: this.item_district.find(
                (item) => item.id == this.selectedDistrict
              ).name,
            }
          : {}),
          ...(this.subService ? {subservice: this.towerRadioSubService.find((item)=> item.search(this.subService) >=0 )}: {}),
      }
      await this[GET_TOWER_RADIO_BROADCASTING](params)
      this.loadingTable = false
    },
    async handleGetOrganizersRadioBroadcasting(){
      this.loadingChart = true
      const params = {
        ...(this.selectedProvince
          ? {
              province: this.item_province.find(
                (item) => item.id == this.selectedProvince
              ).name,
            }
          : {}),
        ...(this.selectedCity
          ? {
              city: this.item_city.find((item) => item.id == this.selectedCity)
                .name.replace('KAB. ', '').replace('KOTA ', ''),
            }
          : {}),
          ...(this.subService ? {subservice: this.towerRadioSubService.find((item)=> item.search(this.subService) >=0 )}: {}),
      }
      console.log('service get', this.subService);
      await this[GET_ORGANIZERS_RADIO_BROADCASTING](params)
      this.loadingChart = false
      this.organizerRadioBroadcastingChartTitle = this.selectedArea ? this.selectedArea.toLowerCase().replace(/\b\w/g, s => s.toUpperCase()) : 'Nasional'
    },
    changeLimitPagination(val){
      this.pagination ={
        ...this.pagination,
        page: 1,
        limit: val
      }
    },
    handleApply(){
      this.pagination = {
        ...this.pagination,
        page: 1,
      };
      this.subService = ""
      this.handleGetOrganizersRadioBroadcasting()
    },
    handleReset(){
      this.resetDataChart()
      this.resetDataTable()
    },
    resetDataChart(){
      this.selectedProvince = 0;
      this.selectedCity = 0;
      this.handleGetOrganizersRadioBroadcasting()
    },
    changeService(val){
      console.log(val);
      if(this.subService === val){
        this.subService = ""
      }else{
        this.subService= val
      }
      this.handleGetOrganizersRadioBroadcasting();
      this.handleGetTowerRadioBroadcasting()
    },
    resetDataTable(){
      this.selectedProvince = 0;
      this.selectedCity = 0;
      this.selectedDistrict = 0;
      this.pagination = {
        ...this.pagination,
        page: 1,
        limit: 10
      }
    },
  },
  watch: {
    pagination: {
      handler() {
        this.handleGetTowerRadioBroadcasting();
      },
      deep: true,
    },
  },
  mounted() {
    this.initialize();
  },
};
