<template>
  <div class="pmt-flex is-row wrap row-gap-2 pl-5 pr-5 pt-2 pb-8">
    <button
      is="v-btn"
      active-class="active-link"
      :class="[
        `text-capitalize text-capitalize pl-6 pr-6`,
        isPage == 'Operator Cellular' ? `white--text` : ``,
      ]"
      rounded
      :outlined="isPage == 'Operator Cellular' ? false : true"
      :to="{ path: link.cellular }"
    >
      {{ $t("general.menu.operator_cellular") }}
    </button>
    <button
      is="v-btn"
      active-class="active-link"
      :class="[
        `text-capitalize text-capitalize pl-6 pr-6 ml-6`,
        isPage == 'Fixed Broadband' ? `white--text` : ``,
      ]"
      rounded
      :outlined="isPage == 'Fixed Broadband' ? false : true"
      :to="{ path: link.fo }"
    >
      {{ $t("general.menu.fixed_broadband") }}
    </button>
    <button
      v-if="link.pos"
      is="v-btn"
      active-class="active-link"
      :class="[
        `text-capitalize text-capitalize pl-6 pr-6 ml-6`,
        isPage == 'Data Pos' ? `white--text` : ``,
      ]"
      rounded
      :outlined="isPage == 'Data Pos' ? false : true"
      :to="{ path: link.pos }"
    >
      {{ $t("general.menu.data_post") }}
    </button>
    <button
     v-if="link.tv"
      is="v-btn"
      active-class="active-link"
      :class="[
        `text-capitalize text-capitalize pl-6 pr-6 ml-6`,
        isPage == 'Data Television Broadcasting' ? `white--text` : ``,
      ]"
      rounded
      :outlined="isPage == 'Data Television Broadcasting' ? false : true"
      :to="{ path: link.tv }"
    >
      {{ $t("general.menu.data_television_broadcast") }}
    </button>
    <button
      v-if="link.radio"
      is="v-btn"
      active-class="active-link"
      :class="[
        `text-capitalize text-capitalize pl-6 pr-6 ml-6`,
        isPage == 'Data Radio Broadcasting' ? `white--text` : ``,
      ]"
      rounded
      :outlined="isPage == 'Data Radio Broadcasting' ? false : true"
      :to="{ path: link.radio }"
    >
      {{ $t("general.menu.data_radio_broadcast") }} 
    </button>
  </div>
</template>
<script>
export default {
  props: {
    link: {
      type: Object,
      default: {
        cellular: "/",
        fo: "/",
        pos:"/",
        tv: "/",
        radio: "/"
      },
    },
    isPage: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss">
.active-link {
  box-shadow: unset !important;
  background: #134889 !important;
}
.row-gap-2{
  row-gap: 16px;
}
</style>
