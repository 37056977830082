import { render, staticRenderFns } from "./SpeedTable.vue?vue&type=template&id=00a2ba06&scoped=true"
import script from "./SpeedTable.js?vue&type=script&lang=js&external"
export * from "./SpeedTable.js?vue&type=script&lang=js&external"
import style0 from "./SpeedTable.vue?vue&type=style&index=0&id=00a2ba06&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00a2ba06",
  null
  
)

export default component.exports