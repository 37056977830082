<template>
  <div id="chart">
    <div class="d-flex align-items-end justify-content-between">
      <div class="d-flex">
        <v-btn @click="setDataLineChart" class="mr-4" is="v-btn" active-class="active-link"
          id="updateTotalTicket" style=" color: black;">Total Ticket</v-btn>
        <v-btn @click="setDataLineChart" class="mr-4" is="v-btn" active-class="active-link"
          id="updateOpenedTicket" style=" color: black;">Opened Ticket</v-btn>
        <v-btn @click="setDataLineChart" class="mr-4" is="v-btn" active-class="active-link"
          id="updateClosedTicket" style=" color: black;">Closed Ticket</v-btn>
      </div>
    </div>
    <apexchart ref="realtimeChart" type="bar" height="300" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts
  },
  data: function () {
    return {
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      },
      chartOptions: {
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 3,
          },
        },
        chart: {
          id: 'basic-bar'
        },
        colors: ["#F3B415", "#FF0000", "#6A6E94", "#33A1FD", "#00A7C6"],
        xaxis: {
          categories: ['Indosat', 'Telkomsel', 'XL', 'Tri', 'Smartfren',]
        },
        yaxis: {
          max: 35,
        },
      },
      series: [{
        name: 'provider',
        data: [35, 20, 14, 12, 10],
      }]
    }
  },
  methods: {
    getRandomArbitrary() {
      return Math.floor(Math.random() * 35);
    },
    setDataLineChart() {

      this.series[0].data.splice(0, 1);
      this.series[0].data.push(this.getRandomArbitrary());
      this.updateSeriesLine();

    },
    updateSeriesLine() {
      this.$refs.realtimeChart.updateSeries([{
        data: this.series[0].data,
      }]);
    },
  },

};
</script>

<style></style>