import { mapActions, mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
import {
  GET_ORGANIZERS_TV_BROADCASTING,
  GET_TOWER_AREA,
  GET_TOWER_TV_BROADCASTING,
} from "../../../../../../store/modules/tv.module";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    selectedArea: "",
    searchArea: "",
    pagination: {
      page: 1,
      limit: 10,
      totalItems: 0,
      rowsPerPageItems: [10, 20, 30, 40, 500],
    },
    organizerTVBroadcastingChartTitle: "",
    chartOptions: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 2,
        },
      },
      colors: ["#CB4F4F", "#EA8426", "#CB1F4F", "#CB411F", "#F4F", "#F11"],
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      fill: {
        opacity: 1,
      },
      title: {
        text: "",
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#263238",
        },
      },
    },
    headers: [
      {
        text: "ANT MDT",
        value: "ant_mdl",
      },
      {
        text: "BTRIM",
        value: "btrim",
      },
      {
        text: "ED MDL",
        value: "eq_mdl",
      },
      {
        text: "ED PWR",
        value: "eq_pwr (wa",
      },
      {
        text: "FREQ",
        value: "freq",
      },
      {
        text: "GAIN",
        value: "gain",
      },
      {
        text: "HGT ANT",
        value: "hgt_ant",
      },
      {
        text: "HGT ANT 1",
        value: "hgt_ant_1",
      },

      {
        text: "LOSS",
        value: "loss",
      },

      {
        text: "SID LAT",
        value: "sid_lat",
      },

      {
        text: "SID LONG",
        value: "sid_long",
      },
      {
        text: "PENYELENGGARA",
        value: "penyelenggara",
      },
    ],
    loadingTable: false,
    loadingChart: false,
    desserts: [],
  }),
  computed: {
    ...mapGetters([
      "towerTVBroadcasting",
      "paginationTowerTVBroadcasting",
      "dateUpdateOrganizerTVBroadcasting",
      "organizerTVBroadcasting",
      "towerArea",
    ]),
    organizerTowerTVChartOptions: function () {
      return {
        ...this.chartOptions,
        xaxis: {
          categories: this.organizerTVBroadcasting.categories || [],
          // categories: ['Bali-NTT', 'Jawa', 'Kalimantan', 'Maluku-Papua', 'Sulawesi', 'Sumatera']
        },
        title: {
          ...this.chartOptions.title,
          text: `Total Penyelenggara Penyiaran TV ${this.organizerTVBroadcastingChartTitle}`,
        },
      };
    },
  },
  methods: {
    ...mapActions([
      GET_ORGANIZERS_TV_BROADCASTING,
      GET_TOWER_AREA,
      GET_TOWER_TV_BROADCASTING,
    ]),
    initialize() {
      this[GET_TOWER_AREA]();
      this.handleGetTowerTVBroadcasting();
      this.handleGetOrganizersTVBroadcasting();
    },

    async handleGetTowerTVBroadcasting() {
      this.loadingTable = true;
      const { page, limit } = this.pagination;
      const params = {
        page,
        limit,
        ...(this.selectedArea ? { area: this.selectedArea } : {}),
      };
      await this[GET_TOWER_TV_BROADCASTING](params);
      this.loadingTable = false;
    },
    async handleGetOrganizersTVBroadcasting() {
      this.loadingChart = true;
      const params = {
        ...(this.selectedArea ? { area: this.selectedArea } : {}),
      };
      await this[GET_ORGANIZERS_TV_BROADCASTING](params);
      this.loadingChart = false;
      this.organizerTVBroadcastingChartTitle = this.selectedArea
        ? this.selectedArea
            .toLowerCase()
            .replace(/\b\w/g, (s) => s.toUpperCase())
        : "Nasional-Regional Besar";
    },
    changeLimitPagination(val) {
      this.pagination = {
        ...this.pagination,
        page: 1,
        limit: val,
      };
    },
    resetDataChart() {
      this.selectedArea = "";
      this.handleGetOrganizersTVBroadcasting();
    },
    resetDataTable() {
      this.selectedArea = "";
      this.pagination = {
        ...this.pagination,
        page: 1,
        limit: 10,
      };
    },
    handleApply() {
      this.pagination = {
        ...this.pagination,
        page: 1,
      };

      this.handleGetOrganizersTVBroadcasting();
    },
    handleReset() {
      this.resetDataChart();
      this.resetDataTable();
    },
  },
  watch: {
    pagination: {
      handler() {
        this.handleGetTowerTVBroadcasting();
      },
      deep: true,
    },
  },
  mounted() {
    this.initialize();
  },
};
