// import AnalysisMaps from "../../components/dashboard/AnalysisMaps.vue";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import JwtServices from "../../../services/jwt.services";
import NavbarBigScreen from "../../../layouts/NavbarFull/NavbarBigScreen.vue";
import {
  GET_ANALYSIS_CITY,
  GET_ANALYSIS_DISTRICT,
  SET_ACTIVE_FILTER,
  GET_ANALYSIS_PROVINCE,
} from "../../../store/modules/analysis.module";

import {
  GET_PROVINCE,
  GET_CITY,
  GET_DISTRICT,
  GET_SUB_DISTRICT,
} from "../../../store/modules/location.module";
import { GET_OPSEL } from "../../../store/modules/opsel.module";
import { RESET_FILTER } from "../../../store/modules/qoe.module";

export default {
  components: { NavbarBigScreen },
  computed: {
    item_province: function () {
      return this.$store.getters.item_province;
    },
    defaultDate: {
      get: function () {
        return this.selectedQuarter + " " + this.selectedYear;
      },
      set: function (value) {
        var year = value.split(" ");
        this.selectedQuarter = year[0];
        this.selectedYear = year[1];
      },
    },
    item_city: function () {
      return this.$store.getters.item_city;
    },

    item_district: function () {
      return this.$store.getters.item_district;
    },

    item_opsel: function () {
      return this.$store.getters.item_opsel;
    },
  },

  data: () => ({
    pmt_url: process.env.VUE_APP_API_URL,
    date: new Date().toISOString().substr(0, 7),
    selected_opsel_temp: 0,
    menu: false,
    menu2: false,
    height: "700px",
    yearItems: [],
    selected_filter: "",
    selected_province: 0,
    selected_city: 0,
    selected_district: 0,
    searchProvince: "",
    dateDialog: false,
    currentDate: "",
    searchCity: "",
    selectedQuarter: 0,
    selectedYear: 0,
    searchDistrict: "",
    quarterItems: ["Q1", "Q2", "Q3", "Q4"],
    selectedSignalStrength: "4G",
    signalStrength: ["2G", "3G", "4G"],
    selectedSpeedTest: "4G",
    speedTest: ["3G", "4G"],
    parameter: [
      {
        id: 0,
        name: "Parameter",
      },
      {
        id: 1,
        name: "POI",
      },
      {
        id: 2,
        name: "Event",
      },
    ],
    selected_tech: 0,
    selected_param: 0,
    opsel_selected: {},
    technology_selected: "",
    province: "",
    city: "",
    district: "",
    show_data: false,
    selected_data: "",
    filter_selected: true,
    cov_qoe_ss: {},
    cov_qoe_dl: {},
    skeleton: true,
    message: "",
    // json_data: [],
    json_fields: {
      Name: "name",
      "Bad Bad": "bad_bad",
      "Bad Fair": "bad_fair",
      "Bad Good": "bad_good",
      "Fair Bad": "fair_bad",
      "Fair Fair": "fair_fair",
      "Fair Good": "fair_good",
      "Good Bad": "good_bad",
      "Good Fair": "good_fair",
      "Good Good": "good_good",
      "Not Covered": "not_covered",
      Total: "total",
    },
    loadingDownload: false,
  }),

  methods: {
    clearCachedItems() {
      this.$refs.autocomplete.cachedItems = [];
    },

    getProvince() {
      this.$store.dispatch(GET_PROVINCE);
    },

    getOperatorCellular() {
      this.$store.dispatch(GET_OPSEL);
    },

    selectCity(value) {
      for (let data of this.item_province) {
        if (value == data.id) {
          this.province = data.name;
        }
      }
      this.selected_filter = "province";
      if (this.selected_province == this.province_selected) {
        this.$store.dispatch(GET_CITY, this.selected_province);
      } else {
        this.selected_city = 0;
        this.selected_district = 0;
        this.selected_sub_district = 0;
        this.$store.dispatch(GET_CITY, this.selected_province);
      }
    },

    selectDistrict(value) {
      for (let data of this.item_city) {
        if (value == data.id) {
          this.city = data.name;
        }
      }
      this.selected_filter = "city";
      this.$store.dispatch(GET_DISTRICT, this.selected_city);
    },

    selectSubDistrict(value) {
      for (let data of this.item_district) {
        if (value == data.id) {
          this.district = data.name;
        }
      }
      this.selected_filter = "district";
      this.$store.dispatch(GET_SUB_DISTRICT, this.selected_district);
    },

    addOpselAnaylysis(value) {
      for (let data of this.item_opsel) {
        if (value == data.id) {
          this.opsel_selected = data;
        }
      }
    },

    applyFilter() {
      this.show_data = true;
      this.filter_selected = false;
      let province = null;
      let city = null;
      this.skeleton = true;

      switch (this.selected_filter) {
        case "province":
          this.$store.commit(SET_ACTIVE_FILTER, this.selected_filter);
          for (let data of this.item_province) {
            if (this.selected_province == data.id) {
              province = data;
              this.$store.dispatch(GET_ANALYSIS_PROVINCE, province.name);
            }
          }
          break;
        case "city":
          for (let data of this.item_city) {
            if (this.selected_city == data.id) {
              city = data;
              this.$store.dispatch(GET_ANALYSIS_PROVINCE, city.name);
            }
          }
          this.$store.commit(SET_ACTIVE_FILTER, this.selected_filter);
          this.$store.dispatch(GET_ANALYSIS_CITY, city.name);
          break;
        case "district":
          this.$store.commit(SET_ACTIVE_FILTER, this.selected_filter);
          this.$store.dispatch(GET_ANALYSIS_DISTRICT, this.selected_district);
          break;
      }

      this.getCovQoESS();
      this.getCovQoESpeedTest();
    },

    resetFilter() {
      this.show_data = false;
      this.filter_selected = true;
      this.selected_opsel_temp = 0;
      this.selected_tech = 0;
      this.selected_province = 0;
      this.selected_city = 0;
      this.message = "";
      this.selected_district = 0;
      this.$store.commit(RESET_FILTER);
      this.$store.dispatch(GET_PROVINCE);
      this.defaultDate = this.currentDate;
    },

    selectedTech(value) {
      // console.log(value);
      for (let item of this.technology) {
        if (value == item.id) {
          this.technology_selected = item.name;
        }
      }
    },
    getYearList() {
      const year = new Date().getFullYear();
      const yearMinOne = year - 1;
      const yearPlusOne = year + 1;
      this.selectedYear = year;
      this.yearItems = [yearMinOne, year, yearPlusOne];

      const quarter = Math.floor((new Date().getMonth() + 3) / 3);
      this.selectedQuarter = "Q" + quarter;

      this.currentDate = "Q" + quarter + " " + year;
    },

    getCovQoESS() {
      const data = {
        start_date: "",
        end_date: "",
        cell_operator_id: this.selected_opsel_temp,
        connection_type: this.selectedSignalStrength,
        province_id: this.selected_province,
        city_id: this.selected_city,
        district_id: this.selected_district,
        sub_district_id: 0,
        q_time: this.selectedQuarter + " " + this.selectedYear,
      };

      fetch(process.env.VUE_APP_API_URL + `api/v2/id/analysis/cov-qoe-ss`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JwtServices.getToken()}`,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result.data == null) {
            this.filter_selected = true;
          }
          this.cov_qoe_ss = result.data.analysis;
          this.skeleton = false;
          // console.log(this.cov_qoe_ss);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCovQoESpeedTest() {
      const data = {
        start_date: "",
        end_date: "",
        cell_operator_id: this.selected_opsel_temp,
        connection_type: this.selectedSpeedTest,
        province_id: this.selected_province,
        city_id: this.selected_city,
        district_id: this.selected_district,
        sub_district_id: 0,
        q_time: this.selectedQuarter + " " + this.selectedYear,
      };

      fetch(process.env.VUE_APP_API_URL + `api/v2/id/analysis/cov-qoe-dl`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JwtServices.getToken()}`,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result.data == null) {
            this.filter_selected = true;
            this.message = result.message;
          }
          this.cov_qoe_dl = result.data.analysis;
          this.skeleton = false;
          // console.log(this.cov_qoe_ss);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    isEmpty(obj) {
      for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
          return false;
        }
      }
      return true;
    },

    printImg() {
      console.log("print iamge");
      let el = this.$refs.capture;
      // console.log(el.children);
      html2canvas(el)
        .then((canvas) => {
          console.log;
          canvas.style.display = "none";
          document.body.appendChild(canvas);
          return canvas;
        })
        .then((canvas) => {
          const image = canvas.toDataURL("image/jpeg");
          const a = document.createElement("a");
          a.setAttribute("download", `Analytic Summary_${
            this.opsel_selected.name || "All Operator"
          }_${this.selectedSignalStrength}_${this.selectedSpeedTest}_${
            this.selectedQuarter
          }${this.selectedYear}_${this.province || "All Province"}_${
            this.city || "All City"
          }_${this.district || "All District"}.jpg`);
          a.setAttribute("href", image);
          a.click();
          canvas.remove();
        });
    },
    downloadPDF() {
      this.loadingDownload = true;
      function createHeaders(keys) {
        let result = [];
        for (let i = 0; i < keys.length; i += 1) {
          result.push({
            id: keys[i],
            name: keys[i],
            prompt: keys[i],
            width: 65,
            align: "center",
            padding: 12,
            fontSize: 8,
        
          });
        }
        return result;
      }
      const coverageDownload = {
      };
      for(const prop in this.cov_qoe_dl){
        coverageDownload[prop.split("_").join(" ")] = this.cov_qoe_dl[prop].toString()
      } 
      const coverageSignal = {}
      for(const prop in this.cov_qoe_ss){
        coverageSignal[prop.split("_").join(" ")] = this.cov_qoe_ss[prop].toString()
      }


      let headers = createHeaders([
        "name",
        "bad bad",
        "bad fair",
        "bad good",
        "fair bad",
        "fair fair",
        "fair good",
        "good bad",
        "good fair",
        "good good",
        "not covered",
        "total",
      ]);

      let el = this.$refs.capture;
      // console.log(el.children);
      html2canvas(el)
        .then((canvas) => {
          console.log;
          canvas.style.display = "none";
          document.body.appendChild(canvas);
          return canvas;
        })
        .then((canvas) => {
          console.log(canvas);
          const image = canvas.toDataURL("image/jpeg");
          const doc = new jsPDF({ orientation: "landscape" });
          doc.setFontSize(12);
          doc.text("COVERAGE CovPrediction X SIGMON", 10, 16);
          doc.addImage(image, "JPEG", 40, 25, 210, 100);
          doc.table(10, 135, [{...coverageDownload, name: 'Speed Test Download SIGMON'}, {...coverageSignal, name: 'Signal Strengh SIGMON'}], headers, {
            autoSize: true,
            fontSize: 8.5,
            padding: 3.5,
            printHeaders: true,
          });
          doc.save(`Analytic Summary_${
            this.opsel_selected.name || "All Operator"
          }_${this.selectedSignalStrength}_${this.selectedSpeedTest}_${
            this.selectedQuarter
          }${this.selectedYear}_${this.province || "All Province"}_${
            this.city || "All City"
          }_${this.district || "All District"}.pdf`);
          this.loadingDownload = false;
        })
        .catch((err)=>{
          console.log(err);
          this.loadingDownload = false;
        })
    },
    downloadExcel() {
      this.loadingDownload = true;
      const data = {
        cov_qoe_speed_download: {
          start_date: "",
          end_date: "",
          cell_operator_id: this.selected_opsel_temp,
          connection_type: this.selectedSpeedTest,
          province_id: this.selected_province,
          city_id: this.selected_city,
          district_id: this.selected_district,
          sub_district_id: 0,
          q_time: `${this.selectedQuarter} ${this.selectedYear}`,
        },
        cov_qoe_signal_strength: {
          start_date: "",
          end_date: "",
          cell_operator_id: this.selected_opsel_temp,
          connection_type: this.selectedSignalStrength,
          province_id: this.selected_province,
          city_id: this.selected_city,
          district_id: this.selected_district,
          sub_district_id: 0,
          q_time: `${this.selectedQuarter} ${this.selectedYear}`,
        },
      };
      fetch(
        process.env.VUE_APP_API_URL + `api/v2/id/analysis/download-cov-qoe`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JwtServices.getToken()}`,
          },
        }
      )
        .then((res) => res.blob())
        .then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `Analytic Summary_${
            this.opsel_selected.name || "All Operator"
          }_${this.selectedSignalStrength}_${this.selectedSpeedTest}_${
            this.selectedQuarter
          }${this.selectedYear}_${this.province || "All Province"}_${
            this.city || "All City"
          }_${this.district || "All District"}.xlsx`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
          this.loadingDownload = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingDownload = false;
        });
    },
  },
  mounted() {
    this.getYearList();
  },
  created() {
    this.getProvince();
    this.getOperatorCellular();
    this.getQOESS();
  },
  //   watch: {
  //     cov_qoe_ss(val) {
  //       console.log("signal");
  //       if (val && !this.isEmpty(val)) {
  //         this.json_data = [
  //           {
  //             name: "Speed Test Download SIGMON",
  //             ...this.cov_qoe_dl,
  //           },
  //           {
  //             name: "Signal Strength SIGMON",
  //             ...val,
  //           },
  //         ];
  //       }
  //     },
  //     cov_qoe_dl(val) {
  //       console.log("download");
  //       if (val && !this.isEmpty(val)) {
  //         this.json_data = [
  //           {
  //             name: "Signal Strength SIGMON",
  //             ...this.cov_qoe_ss,
  //           },
  //           {
  //             name: "Speed Test Download SIGMON",
  //             ...val,
  //           },
  //         ];
  //       }
  //     },
  //   },
};
