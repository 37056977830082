<template>
  <div class="post">
    <div class="font-weight-bold mt-5" style="font-size: 24px">
      infografis {{ $t("general.menu.data_television_broadcast") }}
    </div>
    <v-row class="mt-5">
      <v-col cols="auto" v-for="(item, index) in subTab" :key="index">
        <v-btn
          width="100%"
          class="text-capitalize text-grey"
          height="50"
          rounded
          elevation="0"
          @click="changeComponent(item.navigationTo)"
          :class="[
            `text-capitalize`,
            activeItem == item.navigationTo ? `active-btn` : ``,
          ]"
        >
          {{ $t(item.name) }}
        </v-btn>
      </v-col>
    </v-row>

    <!-- Component Data Post Choice -->
    <component :is="television" />
  </div>
</template>

<script>
import TelevisionBroadcastingTower from "./components/TelevisionBroadcastingTower/TelevisionBroadcastingTower.vue";
import CoverageTransmitterTelevision from "./components/CoverageTransmitterTelevision/CoverageTransmitterTelevision.vue";

export default {
  components: {
    TelevisionBroadcastingTower,
    CoverageTransmitterTelevision,
  },
  data: () => ({
    television: TelevisionBroadcastingTower,
    activeItem: "Television Broadcasting Tower",
    subTab: [
      {
        name: "ui.button_television_broadcasting_tower",
        navigationTo: "Television Broadcasting Tower",
      },
      {
        name: "ui.button_coverage_transmitter_television",
        navigationTo: "Coverage Transmitter Television",
      },
    ],
  }),
  methods: {
    changeComponent(value) {
      this.activeItem = value;
      if (value == "Television Broadcasting Tower") {
        this.television = TelevisionBroadcastingTower;
      } else {
        this.television = CoverageTransmitterTelevision;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.post {
  height: 100%;
}

.active-btn {
  background-color: #ffba3b !important;
  color: white !important;
}
.text-grey {
  color: #867e7e;
}
</style>
