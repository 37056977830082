var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"alarm-severity"},[_c('NavbarBigScreen'),_c('NavigationCellAndFo',{attrs:{"link":{
      cellular: '/summary-alarm-severity',
      fo: '/fo/summary-alarm-severity',
    },"is-page":"Operator Cellular"}}),_c('v-row',{staticClass:"mt-10 pa-5"},[_c('v-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"font-weight-bold text-center"},[_vm._v("Major")])]),_c('v-col',[_c('v-slide-group',{staticClass:"pa-4",attrs:{"show-arrows":""},model:{value:(_vm.onboarding),callback:function ($$v) {_vm.onboarding=$$v},expression:"onboarding"}},_vm._l((_vm.alarm_major),function(item,i){return _c('v-slide-item',{key:i},[_c('v-card',{staticClass:"pa-5 ma-5",attrs:{"width":"100%"}},[_c('div',{staticClass:"font-weight-bold text-center"},[_c('img',{attrs:{"width":"50px","height":"40px","src":_vm.pmt_url + item.opsel.logo,"alt":item.opsel.name}}),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(item.opsel.name))])]),_c('apexchart',{attrs:{"type":"donut","options":_vm.criticalOptions,"series":item.data == null
                  ? [0, 0]
                  : [item.data.active_alarm, item.data.closed_alarm]}}),_c('v-card',{staticClass:"pa-5 mt-5",attrs:{"flat":"","outlined":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('small',[_vm._v("Hardware")]),_c('div',[_vm._v(_vm._s(item.data == null ? 0 : item.data.hardware))])]),_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('small',[_vm._v("Transmission")]),_c('div',[_vm._v(" "+_vm._s(item.data == null ? 0 : item.data.transmission)+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('small',[_vm._v("Power Failure")]),_c('div',[_vm._v(" "+_vm._s(item.data == null ? 0 : item.data.power_failure)+" ")])])],1)],1)],1)],1)}),1)],1)],1),_c('v-row',{staticClass:"mt-10 pa-5"},[_c('v-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"font-weight-bold text-center"},[_vm._v("Critical")])]),_c('v-col',[_c('v-slide-group',{staticClass:"pa-4",attrs:{"show-arrows":""},model:{value:(_vm.onboarding),callback:function ($$v) {_vm.onboarding=$$v},expression:"onboarding"}},_vm._l((_vm.alarm_critical),function(item,i){return _c('v-slide-item',{key:i},[_c('v-card',{staticClass:"pa-5 ma-5",attrs:{"width":"100%"}},[_c('div',{staticClass:"font-weight-bold text-center"},[_c('img',{attrs:{"width":"50px","height":"40px","src":_vm.pmt_url + item.opsel.logo,"alt":item.opsel.name}}),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(item.opsel.name))])]),_c('apexchart',{attrs:{"type":"donut","options":_vm.majorOptions,"series":item.data == null
                  ? [0, 0]
                  : [item.data.active_alarm, item.data.closed_alarm]}}),_c('v-card',{staticClass:"pa-5 mt-5",attrs:{"flat":"","outlined":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('small',[_vm._v("Hardware")]),_c('div',[_vm._v(_vm._s(item.data == null ? 0 : item.data.hardware))])]),_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('small',[_vm._v("Transmission")]),_c('div',[_vm._v(" "+_vm._s(item.data == null ? 0 : item.data.transmission)+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('small',[_vm._v("Power Failure")]),_c('div',[_vm._v(" "+_vm._s(item.data == null ? 0 : item.data.power_failure)+" ")])])],1)],1)],1)],1)}),1)],1)],1),_c('v-row',{staticClass:"mt-10 pa-5"},[_c('v-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"font-weight-bold text-center"},[_vm._v("Minor")])]),_c('v-col',[_c('v-slide-group',{staticClass:"pa-4",attrs:{"show-arrows":""},model:{value:(_vm.onboarding),callback:function ($$v) {_vm.onboarding=$$v},expression:"onboarding"}},_vm._l((_vm.alarm_minor),function(item,i){return _c('v-slide-item',{key:i},[_c('v-card',{staticClass:"pa-5 ma-5",attrs:{"width":"100%"}},[_c('div',{staticClass:"font-weight-bold text-center"},[_c('img',{attrs:{"width":"50px","height":"40px","src":_vm.pmt_url + item.opsel.logo,"alt":item.opsel.name}}),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(item.opsel.name))])]),_c('apexchart',{attrs:{"type":"donut","options":_vm.minorOptions,"series":item.data == null
                  ? [0, 0]
                  : [item.data.active_alarm, item.data.closed_alarm]}}),_c('v-card',{staticClass:"pa-5 mt-5",attrs:{"flat":"","outlined":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('small',[_vm._v("Hardware")]),_c('div',[_vm._v(_vm._s(item.data == null ? 0 : item.data.hardware))])]),_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('small',[_vm._v("Transmission")]),_c('div',[_vm._v(" "+_vm._s(item.data == null ? 0 : item.data.transmission)+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"lg":"4"}},[_c('small',[_vm._v("Power Failure")]),_c('div',[_vm._v(" "+_vm._s(item.data == null ? 0 : item.data.power_failure)+" ")])])],1)],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }